import UrlConstants from '../constants/UrlConstants';

export const HeadMetaData = (productId, profileId, partner) => {

    var metaList = document.getElementsByTagName("meta");
    if (metaList.length > 0) {
        if (UrlConstants.PRODUCT_ID_7 === productId || UrlConstants.PRODUCT_ID_8 === productId) {

            if (UrlConstants.PROFILE_ID_AAMI.includes(profileId || partner)) {
                document.title = 'Home Building Calculator | AAI';
                metaList[0].setAttribute("content","Home Building Calculator | AAI");

            } else if(UrlConstants.PROFILE_ID_TS === profileId) {
                document.title = 'Home Building Calculator | Terri Scheer';
                metaList[0].setAttribute("content","Home Building Calculator | Terri Scheer");

            } else {
                document.title = 'Sum Sure';
                metaList[0].setAttribute("content","Sum Sure");
            }
            document.getElementsByTagName('meta')["description"].content = "Estimate the cost to rebuild your house. Simply type in your address to get started.";
        } else if (UrlConstants.PRODUCT_ID_11 === productId) {
            document.title = 'Strata';
            metaList[0].setAttribute("content","Strata");
            document.getElementsByTagName('meta')["description"].content = "Estimate the cost to rebuild your property.";
        } else {
            document.title = 'Sum Sure';
            metaList[0].setAttribute("content","Sum Sure");
            document.getElementsByTagName('meta')["description"].content = "Estimate the cost to rebuild your property.";
        }
    }
}

export default HeadMetaData;