import React from 'react';
import UrlConstants from '../constants/UrlConstants';
import history from '../util/history';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Button } from '@material-ui/core';
import configLocal from '../commercialConfig';


class NotFoundPage extends React.Component {
    render() {  
        const styles = {
            headerLogoLeftPaddingTop: ".46875rem",
        };
        const {productId} = this.props; 
        const backToHome = () => {
            const { profileId, logout } = this.props;
            logout('');
            history.push(`/products/${productId}/profiles/${profileId}`);
            var win = window.open(`${configLocal.CLAUD_LOGOUT_URL}`);
            setTimeout(function () {
                win.close();
            }, 900);
        };
        const currentPath = window.location.toString();
        const redirectURL = currentPath.indexOf("sumsure.cordell.com.au") >= 0 ? "https://www.corelogic.com.au/products/cordellsumsure" :
            this.getRedirectUrl(currentPath);

        return (
            <div className="error-page">
                {productId !== UrlConstants.PRODUCT_ID_10 && productId !== UrlConstants.PRODUCT_ID_9 && productId !== UrlConstants.PRODUCT_ID_11 &&
                <div> <h2>The requested URL was not found on this server.</h2>
                    {redirectURL.length > 0 && <h2>To find out more information about Cordell Sum Sure go to</h2>}
                    {redirectURL.length > 0 && <h2><a href={redirectURL}>{redirectURL}</a></h2>}
                </div>}
                {(productId === UrlConstants.PRODUCT_ID_10 || productId === UrlConstants.PRODUCT_ID_9 || productId === UrlConstants.PRODUCT_ID_11) &&
                <div><center><h1  style={{
                    fontFamily: styles.globalFontFamily, 
                       textTransform: 'none', }}>We can't seem to find the page <br />
                you're looking for.</h1> 
                <Button
                className="input"
                id="login-button"
                label="backToHome"
                type="primary"
                keyboardFocus={false}
                variant="contained"  
                startIcon={<KeyboardBackspaceIcon />}
                tabIndex={0}
                onClick={()=>backToHome()}
                style={{
                 fontFamily: styles.globalFontFamily,
                    borderRadius: styles.buttonRadius, 
                    width: "12rem",
                    backgroundColor: "rgb(244, 67, 54)",
                    color: "white",
                }}
            >BACK TO HOME</Button></center>
                </div>}
            </div>);
    }

    getRedirectUrl(currentPath) {
        return currentPath.indexOf("sumsure.corelogic.co.nz") >= 0 ? "https://www.corelogic.co.nz/products/cordell-sum-sure" : "";
    }
}

export default NotFoundPage;