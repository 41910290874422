import React from 'react';
import { TextField } from 'material-ui';
import SelectBox from './SelectBox';
import RadioImageBox from './RadioImageBox';
import RadioBox from './RadioBox';
import RadioButtonCustom from './RadioButtonCustom';
import { inputStyle, validationErrorStyle } from '../style/styles';
import PropTypes from 'prop-types';
import { validateTextQuestion } from "../util/QuestionValidator";
import UrlConstants from '../constants/UrlConstants';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const InputField = ({ question, editAnswer, cceEditAnswer, styles, updateErrors, validateUserAnswer, errorMsg, productId, profileId, sectionName }) => {
    const { answerSelectedTextColour, answerFontSize, globalFontFamily } = styles;
    const style = {
        ...inputStyle,
        color: answerSelectedTextColour,
        fontSize: answerFontSize,
        fontFamily: globalFontFamily,
    };
    const inputErrorStyle = {
        ...style,
        borderColor: 'red'
    };
    const onChange = (newAnswer, shouldTriggerReload = true) => {
        if (question.answer !== String(newAnswer.answer) || question.inputType === 'text') {

            if ((productId === UrlConstants.PRODUCT_ID_10 || productId === UrlConstants.PRODUCT_ID_11) && sectionName === 'Building Details') {
                cceEditAnswer(newAnswer, shouldTriggerReload);
            }
            else {
                editAnswer(newAnswer, shouldTriggerReload);
            }
        }
    };

    const clearError = (questionId) => {
        updateErrors(false, '', questionId);
    };

    const addError = (errText, questionId) => {
        updateErrors(true, errText, questionId);
    };

    const validateTextField = (questionData, value, maxChar, event) => {
        let error = validateTextQuestion(questionData, value);
        if (error) {
            addError(error, questionData.id);
        }
        else {
            error = validateUserAnswer(productId, questionData.text.toUpperCase(), value, questionData.id);
            if (error) {
                addError(error, questionData.id);
            }
            else {
                clearError(questionData.id);
                onChange({
                    question: questionData.id, answer:
                        value.charAt(value.length - 1) === '.' ? event.target.value.substring(0, maxChar - 1) : value
                });
            }

        }
    };

    const onBlurTextField = (question1, value, event) => {
        clearError(question1.id);
        onChange({
            question: question1.id, answer:
                value.charAt(value.length - 1) === '.' ? event.target.value.substring(0, 99) : value
        });
    };

    const handleFocus = (e, question1) => {
        if(((question1.id === 302860) || (question1.id === 302839)) && (productId.includes(UrlConstants.PRODUCT_ID_9) > 0 || productId.includes(UrlConstants.PRODUCT_ID_10) > 0
            || productId.includes(UrlConstants.PRODUCT_ID_11) > 0 )){
            e.currentTarget.select();
        }
      };

    const errorStyle = { ...validationErrorStyle, fontFamily: styles.globalFontFamily };
    const errorMsgStyle = { ...validationErrorStyle, fontFamily: styles.globalFontFamily, color: 'rgb(244, 67, 54)', marginTop: '0px' };

    if (question.inputType === 'selectbox' && question.id.toString() !== "303548") {
        return <SelectBox id={question.id.toString()} question={question} onChange={onChange} styles={styles} errorMsg={errorMsg} clearError={clearError} profileId={profileId} />;
    } else if (question.inputType === 'radioimage') {
        const defaultAnswer = parseInt(question.answer, 10);
        return <RadioImageBox id={question.id.toString()} question={question} onChange={onChange} defaultAnswer={defaultAnswer} styles={styles} productId={productId} profileId={profileId} />;
    } else if (question.inputType === 'radio') {
        if ((profileId.includes(UrlConstants.PROFILE_ID_125) > 0) || (profileId.includes(UrlConstants.PROFILE_ID_126) > 0)) {
            const defaultAnswer = parseInt(question.answer, 10);
            return <RadioButtonCustom id={question.id.toString()} question={question} onChange={onChange} defaultAnswer={defaultAnswer} styles={styles} />;
        } else {
            return <RadioBox id={question.id.toString()} question={question} onChange={onChange} styles={styles} productId={productId} profileId={profileId} />;
        }
    } else if ((question.section.name === 'Special Items' ||
    question.section.name === 'Rural Structures' ||
    question.section.name === 'External Features' || question.section.name === 'Building Details' ||
    question.id.toString() === "303548") && question.range == null) {
    let questionAnswer = question.answer;
    if ( question.id === 303548 &&  question.answer) {
    let selectedAnswer = question.options.filter(option =>  option.id.toString() === question.answer.toString())[0];
            if (selectedAnswer) {
                questionAnswer = selectedAnswer.label;
            }
    }
        return <TextField type="text" id={question.id.toString()}
            className="text-area"
            min="0" disabled={question.id === 303548}
            tabIndex={0}
            aria-describedby={question.id.toString()}
            value= {questionAnswer}
            onChange={(event, newValue) => {
                const tempValue = newValue.substring(0, 100);
                onChange({
                    question: question.id, answer:
                        tempValue.charAt(tempValue.length - 1) === '.' ? newValue.substring(0, 99) : tempValue
                }, false);
            }}
            onBlur={(event) => {
                onBlurTextField(question, event.target.value.substring(0, 99), event)
            }}
            errorText={errorMsg}
            errorStyle={errorStyle}
            inputStyle={errorMsg ? inputErrorStyle : style}
            underlineShow={false}
        />;
    } else {
        return textFieldMessage({question, errorMsg, onChange}, validateTextField, handleFocus, inputErrorStyle, style, errorMsgStyle);
    }
};

InputField.propTypes = {
    question: PropTypes.object.isRequired,
    editAnswer: PropTypes.func.isRequired,
    styles: PropTypes.object,
};

export default InputField;

function textFieldMessage({question, errorMsg, onChange}, validateTextField, handleFocus, inputErrorStyle, style, errorMsgStyle) {
    let maxChar = question.section.name === 'Special Items' ? 8 : 5;

    return <FormControl><TextField type="number" id={question.id.toString()}
        className={question.section.name === 'Special Items' ? 'special-text' : 'text-number'}
        min="0"
        tabIndex={0}
        aria-describedby={"more-info-" + question.id.toString() + " help-text-" + question.id.toString() + " error-message-" + question.id.toString()}
        aria-invalid={errorMsg ? true : undefined}
        disabled={question.id === 303232}
        value={question.answer}
        onChange={(event, newValue) => {
            const tempValue = newValue.substring(0, maxChar);
            onChange({
                question: question.id, answer: tempValue.charAt(tempValue.length - 1) === '.' ? newValue.substring(0, maxChar - 1) : tempValue
            }, false);
            validateTextField(question, newValue.substring(0, maxChar), maxChar, event);
        } }
        onBlur={(event) => {
            validateTextField(question, event.target.value.substring(0, maxChar), maxChar, event);
        } }
        onFocus={(event) => { handleFocus(event, question); } }
        inputStyle={errorMsg ? inputErrorStyle : style}
        underlineShow={false} />
        {errorMsg && <FormHelperText id={"error-message-" + question.id.toString()} style={errorMsgStyle}>{errorMsg}</FormHelperText>}
    </FormControl>;
}
