import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import CCERebuildCostPage from '../components/CCERebuildCostPage';
import {performCalculation} from '../actions/calculationActionCreator';
import AnalyticsPage from '../util/AnalyticsPage';
import {trackSegmentPageView} from "../actions/segmentActionsCreator";
import {updateCurrentTab} from '../actions/searchActionCreator';
import { updateRiskInNewEstimate} from '../actions/questionsActionCreator';

const mapStateToProps = (state, defaultProps) => {
    return {
        isEstimateDirect: state.isEstimateDirect,
        displayRefreshWarning: state.displayRefreshWarning,
        debug: state.debug,
        addressChosen: state.addressChosen,
        propertyId: state.addressChosen.propertyId,
        questions: state.questions,
        result: state.result,
        productId: state.productId,
        profileId: state.profileId,
        partnerId: state.partnerId,
        images: state.profile.images,
        styles: state.profile.styles,
        text: state.profile.text,
        helpContent: state.helper.helpInformationContent,
        helpDialogOpen: state.helper.helpInformationOpen,
        segmentTrackingCode: state.profile.segmentTrackingCode,
        segmentAnalytics: state.segmentAnalytics,
        attributesCheck: state.profile.display.attributesCheck,
        showResult: state.showResult,
        contentInsurance: state.profile.display.contentInsurance,
        errors: state.errors,
        showDisclaimerAtBottom: state.profile.display.questionsPageDisclaimerShowAtBottom,
        rebuildCostSectionHeader: state.profile.text.rebuildCostHeader,
        estimateID: state.estimateID,
        riskId: state.riskId,
        buildingGrp: state.buildingGrp,
        estimatePrice: state.estimatePrice,
        handleChange: defaultProps.handleChange,
        isEditEstimate: defaultProps.isEditEstimate,
        isEmailDownload: defaultProps.isEmailDownload,
        riskDetail: state.riskDetail,
        currentTab: state.currentTab,
        postcode: state.postcode,
        companyDisclaimer: state.loggedInUserDetail.companyDisclaimer,
        divisionDisclaimer: state.loggedInUserDetail.divisionDisclaimer,
        homePath: state.homePath,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        performCalculation,
        trackSegmentPageView: trackSegmentPageView,
        updateCurrentTab:  updateCurrentTab,
        updateRiskDetail: updateRiskInNewEstimate,
    }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        ...dispatchProps,
        calculate: () => {
            let obj1 = {
                productId : stateProps.productId,
                profileId : stateProps.profileId,
                propertyId : stateProps.propertyId,
                address : stateProps.addressChosen.suggestion,
                questions : stateProps.questions
            };
            dispatchProps.performCalculation(obj1,
                stateProps.contentInsurance, stateProps.partnerId, stateProps.estimateID, stateProps.riskId, stateProps.buildingGrp, stateProps.estimatePrice);
        },
        onLoad: () => {
            const pageDetails = new AnalyticsPage(stateProps.productId, stateProps.profileId, 'Rebuild Cost Page', ownProps.location.pathname, stateProps.sessionTrackId, "RebuildCostPage", stateProps.partner);
            dispatchProps.trackSegmentPageView("Rebuild Cost Page", pageDetails);
        },
        openHelpInformationDialog: (open, content) => {
            dispatchProps.openHelpInformationDialog(open, content);
        },
        showResultAfterConfirmation: () => {
            dispatchProps.showResultSection(true);
        },
        openLetUsKnowDialog: (open, address, propertyId) => {
            dispatchProps.openLetUsKnowDialog(open, address, propertyId);
        },
        partnerId: stateProps.partner,
        updateRiskDetail: () => {
            dispatchProps.updateRiskDetail(stateProps.productId, stateProps.profileId, stateProps.riskId, stateProps.riskDetail);
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CCERebuildCostPage);
