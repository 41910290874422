import React from 'react';
import { filter, groupBy, isEmpty, sortBy, findIndex } from 'lodash';
import CCESection from './CCESection';
import SpinnerContainer from '../containers/SpinnerContainer';
import HelpInformationDialogContainer from '../containers/HelpInformationDialogContainer';
import PropTypes from 'prop-types';
import LetUsKnowDialogContainer from "../containers/LetUsKnowDialogContainer";
import { Button } from '@material-ui/core';
import UrlConstants from '../constants/UrlConstants';
import FormHelperText from '@material-ui/core/FormHelperText';

class BuildingDetailsPage extends React.Component {

    componentWillMount() {
        const { onLoad, onFetch, productId, profileId, addressChosen, debug, partnerId, questions, riskId, saveRiskDetail, riskDetail, isEditEstimate,
        updateCurrentTab, updateRiskDetail, currentTab, postcode, fetchStrataBillingToken } = this.props;
        onLoad();
        if (productId === '11') {
            fetchStrataBillingToken();
        }
        if (riskId === null) {
            saveRiskDetail(productId, profileId, riskDetail, addressChosen.suggestion, addressChosen.propertyId, postcode);
        } else if (riskId != null && !isEditEstimate && currentTab === UrlConstants.RISK_DETAILS) {
            updateRiskDetail();
        }
        updateCurrentTab(UrlConstants.BUILDING_DETAILS);

        if (isEmpty(questions.data)) {
            onFetch({productId, profileId}, addressChosen.propertyId, addressChosen.suggestion, [], debug, partnerId);
        }

        if (isEmpty(this.props.buildingGrp) && (isEditEstimate === undefined || !isEditEstimate)) {
            this.onAddBuilding();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { productId, questions, validateMandatoryFields, isEditEstimate } = this.props;
        if (
            questions !== prevProps.questions &&
            (prevProps.questions !== null && questions !== null &&
                questions.data !== prevProps.questions.data) && questions.data.length > 0 && !isEditEstimate
        ) {
            validateMandatoryFields(productId, this.props.questions);
        }
    }
    scrollToFirstError = () => {
        const { errors } = this.props;
        if (errors && errors.length > 0) {
            let errorIds = errors.map(error => error.questionId);
            let elements = document.getElementsByClassName("questionLayout");
            let firstElement = [...elements].filter(element => errorIds.includes(parseInt(element.id.split("-")[1], 10)))[0];
            if (firstElement) {
                firstElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                })
            }
        }
    };

    componentWillUnmount() {
        window.removeEventListener('scroll', this.refreshHiddenQuestions);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.refreshHiddenQuestions);
    }

    handleClose = () => {
        const { handleChange } = this.props;
        handleChange(0);
    };

    refreshHiddenQuestions = () => {
        const activeElementId = document.activeElement ? document.activeElement.id : null;
        if (activeElementId && document.activeElement.value && ((activeElementId === "302854" )
            || (activeElementId === "302839" || activeElementId === "302860"))) {
            document.getElementById(activeElementId).blur();
        }
    };

    onAddBuilding = () => {
        const { onAddMultipleBuilding, buildingGrp } = this.props;
        if (buildingGrp.length < 9) {
            onAddMultipleBuilding(this.props.productId, this.props.profileId, this.props.addressChosen.propertyId, this.props.addressChosen.suggestion, buildingGrp);
        }
    }
    onRemoveBuilding = (indexValue) => {
        const { onRemoveMultipleBuilding, updateErrors } = this.props;
        onRemoveMultipleBuilding(indexValue);
        updateErrors(false, '', 302875);
    }

    handleExpandClick = (indexValue, secondIndex) => {
        const { onHandlingPanel } = this.props;
        onHandlingPanel(indexValue, secondIndex);
    }

    render() {
        const { styles, text, handleChange, questions, isEmailDownload, isEditEstimate, postcode, isCurrentTabError } = this.props;
        let buildingDetailsNextPageBtn = this.props.productId === UrlConstants.PRODUCT_ID_10 ? "Rural Structures" : "Special Items";
        let buildingWhatNextNavigation = this.props.productId === UrlConstants.PRODUCT_ID_10 ? UrlConstants.RURAL_STRUCTURES : UrlConstants.SPECIAL_ITEMS;
        let buildingDetails;
        if (this.props.questions !== null) {
            const index = findIndex(questions.data, (question) => {
                return this.props.productId === UrlConstants.PRODUCT_ID_11 ? question.id === 302839 : question.id === 302860;
            });
            if (index >= 0) {
                questions.data[index] = { ...questions.data[index], answer: this.props.postcode, visible: false, isInvalid: false, answerSource: "grid", helpEnabled: true };
            }
        }
        const buildingQuestions = filter(questions.data, (question) => {
            return (question.section.name.toLowerCase().indexOf('Building Details'.toLowerCase()) >= 0) && question.visible;
        });

        const questionsSortedBySection = sortBy(buildingQuestions, (question) => {
            return question.section.lineNo;
        });

        const groupedBuildingQuestions = groupBy(questionsSortedBySection, (param) => {
            return param.section.name;
        });
        const handleNext = (event, newValue) => {
            handleChange(buildingWhatNextNavigation);
        };
        const handlePrevious = (event, newValue) => {
            handleChange(UrlConstants.RISK_DETAILS);
        }
        const errorMsgStyle = { fontFamily: styles.globalFontFamily };

        return (
            <div className='ccebuildingdetails'>
                <div className="questions" style={{
                    fontFamily: styles.globalFontFamily,
                }}>
                    <SpinnerContainer />
                    <div className="cce-questions-wrapper">
                        {
                            Object.keys(groupedBuildingQuestions).map((section, i) => {
                                buildingDetails = <CCESection key={i} styles={styles} postcode={postcode} section={{
                                    name: section,
                                    questions: groupedBuildingQuestions[section],
                                    sectionTileColour: styles.sectionTileColour,
                                }} onHelpInformationDialogOpen={this.props.openHelpInformationDialog} text={text}
                                    buildingGrp={this.props.buildingGrp} onAddBuilding={this.onAddBuilding}
                                    handleExpandClick={this.handleExpandClick} onRemoveBuilding={this.onRemoveBuilding}
                                    expanded={this.props.expanded} expandedIndex={this.props.expandedIndex} onDefaultBuilding={this.onDefaultBuilding} />;
                                return null;
                            })
                        }
                        {buildingDetails}
                        {((this.props.errors != null && this.props.errors !== undefined
                            && this.props.errors.length > 0) ||
                            (this.props.depdencyErrors != null && this.props.depdencyErrors !== undefined
                                && this.props.depdencyErrors.length > 0)) &&
                            <FormHelperText autofocus style={errorMsgStyle} className="cce-estimate-error">
                                Please fill in all mandatory fields marked with an asterisk (*)
                                with valid values </FormHelperText>}
                        <div className="action-nav">
                            <div className="nav-previous"><Button className="action_previous" variant="outlined" id="tab-2"
                                rounded="true"
                                size="large"
                                tabIndex={0}
                                disabled={isEditEstimate ? true : false}
                                disableTouchRipple={true}
                                disableFocusRipple={false}
                                onClick={handlePrevious}
                            > &larr; Risk Details
                        </Button></div>
                            <div className="nav-next"><Button className="action_next" variant="outlined" id="tab-2"
                                rounded="true"
                                size="large"
                                tabIndex={0}
                                disabled={isEmpty(questions.data) || isEmailDownload
                                    || (this.props.errors !== null && this.props.errors !== undefined
                                        && this.props.errors.length > 0 && !isCurrentTabError(questions, this.props.errors))
                                    || (this.props.depdencyErrors !== null && this.props.depdencyErrors !== undefined
                                        && this.props.depdencyErrors.length > 0) ? true : false}
                                disableTouchRipple={true}
                                disableFocusRipple={false}
                                onClick={handleNext}
                            > {buildingDetailsNextPageBtn} &rarr;
                        </Button></div>
                        </div>
                    </div>
                    <HelpInformationDialogContainer />
                    <LetUsKnowDialogContainer />

                </div>

            </div>

        );
    }
}

BuildingDetailsPage.propTypes = {
    questions: PropTypes.object.isRequired,
    result: PropTypes.object.isRequired,
    displayRefreshWarning: PropTypes.bool.isRequired,
    addressChosen: PropTypes.object.isRequired,
    productId: PropTypes.string.isRequired,
    styles: PropTypes.object,
    text: PropTypes.object,
    profileId: PropTypes.string.isRequired,
    debug: PropTypes.bool,
    onFetch: PropTypes.func.isRequired,
    onLoad: PropTypes.func.isRequired,
    stateProductId: PropTypes.string,
    partner: PropTypes.string,
    openHelpInformationDialog: PropTypes.func,
    attributesCheck: PropTypes.bool.isRequired,
    onAddMultipleBuilding: PropTypes.func,

};

export default BuildingDetailsPage;