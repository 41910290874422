import React from 'react';
import AppBar from 'material-ui/AppBar';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import UrlConstants from '../constants/UrlConstants';
import HamburgerMenu from 'react-hamburger-menu';
import { Button } from '@material-ui/core';

const CCEHeader = ({ debug, images, styles, text, logoLeftUrl, productId, widthChange, handleMenuClick, open, profileId, companyLogo, divisionLogo,
    handleLogout, loggedInUserName, loggedInEmail, pageName, toggleMenu, handleToggleMenu, newEstimate, handleNewEstimate, handleManageNavigation, handleAdminNavigation }) => {
    const { headerLogoLeftUrl, mobileHeaderLogoImageLeftUrl } = images;
    const { headerTitle } = text;
    const debugModeTextAlign = debug ? 'right' : '';
    const isMobile = widthChange <= UrlConstants.MOBILE_INNER_WIDTH;

    const iconLeftStyle = getIconLeftStyle(styles);

    const iconRightStyle = getIconRightStyle(styles);

    const iconMobileRightStyle = getIconMobileRightStyle(styles);

    let renderCCELeftElement = () => {
        let logo = null;
        if (mobileHeaderLogoImageLeftUrl || headerLogoLeftUrl) {
            logo =
                <div className="header-banner">
                    <div className="cce-leftElement">
                        <picture>
                            <source media='(max-width: 480px)' style={{ width: 'auto', height: 'auto' }} className="logo" srcSet={mobileHeaderLogoImageLeftUrl} />
                            <NavLink to={'/products/' + productId + '/profiles/' + profileId + UrlConstants.MANAGE} className="cl-logo" >
                                <img src={headerLogoLeftUrl} alt='Left Logo' className="logo" style={{ width: 'auto', height: 'auto' }} />
                            </NavLink>
                        </picture>
                    </div>
                    <div className="cce-title">
                        <span className="cce-span">{headerTitle}</span>
                    </div>
                </div>
        }

        if (logo && logoLeftUrl) {
            logo = <a href={logoLeftUrl} target="_blank" rel="noopener noreferrer">{logo}</a>;
        }
        return logo;
    };

    const newEstimatePage = newEstimate !== undefined && newEstimate !== null && newEstimate === true;

    const keyBoardManageEvent = event => {
        if (event.key === 'Enter')
          handleManageNavigation.call();
    };
    const keyBoardAdminEvent = event => {
        if (event.key === 'Enter')
          handleAdminNavigation.call();
    };

    const displayMobileMenu = () => {
        let keyBoardEstimateEvent = event => {
            if (event.key === 'Enter')
                handleNewEstimate.call();
        }
        return (
            <div>
            {newEstimatePage && <ul className='hamburgerDropDown' >
                <li className='nav-link mob-nav-link'> <Button id="navigateManage" className="anchorTag-btn anchorTag-btn-mob" onMouseDown={handleManageNavigation}
                    onKeyPress={keyBoardManageEvent}>Estimate Management</Button>
                </li>
                <li className='nav-link mob-nav-link'> <Button id="navigateEstimate" className="anchorTag-btn anchorTag-btn-mob" onMouseDown={handleNewEstimate}
                    onKeyPress={keyBoardEstimateEvent}>New Estimate</Button>
                </li>
                <li className='nav-link mob-nav-link'> <Button id="navigateAdmin" className="anchorTag-btn anchorTag-btn-mob" onMouseDown={handleAdminNavigation}
                    onKeyPress={keyBoardAdminEvent}>Administration</Button>
                </li>
                <li className='nav-link'><NavLink
                    to={{
                        pathname: '/products/' + productId + '/profiles/' + profileId + '/' + pageName,
                        search: '?logout=true',
                        state: { title: 'logout ' }
                    }} > <span>Logout</span ></NavLink></li>
            </ul > }
            {!newEstimatePage && <ul className='hamburgerDropDown' >
                <li className='nav-link'>
                    <NavLink to={'/products/' + productId + '/profiles/' + profileId + UrlConstants.MANAGE} ><span>Estimate Management</span></NavLink></li>
                <li className='nav-link'>
                        <NavLink to={'/products/' + productId + '/profiles/' + profileId + UrlConstants.ESTIMATE}> <span>New Estimate</span ></NavLink></li>
                <li className='nav-link'>
                    <NavLink to={'/products/' + productId + '/profiles/' + profileId + UrlConstants.ADMIN}> <span>Administration</span ></NavLink></li>
                <li className='nav-link'><NavLink
                    to={{
                        pathname: '/products/' + productId + '/profiles/' + profileId + '/' + pageName,
                        search: '?logout=true',
                        state: { title: 'logout ' }
                    }} > <span>Logout</span ></NavLink></li>
            </ul >
            }
            </div>
        )
    }

    const hamburgerMenu = () => {
        return (
            <HamburgerMenu
                isOpen={false}
                menuClicked={handleMenuClick.bind(this)}
                width={40}
                height={20}
                strokeWidth={4}
                rotate={0}
                color='white'
                borderRadius={0}
            />
        )
    }

    let renderCCERightElement = () => {
        let keyBoardEvent = event => {
            if(event.key === 'Enter')
                handleLogout.call();
        }
        let keyBoardEstimateEvent = event => {
            if (event.key === 'Enter')
                handleNewEstimate.call();
        }
        let logo = null;
        let activeScreen = pageName === 'manage' ? UrlConstants.ESTIMATE_MANAGEMENT : whenPageNameEqualsEstimate(pageName);
        let inital = loggedInUserName.includes(' ')? loggedInUserName.split(' ')[0].charAt(0) +
        loggedInUserName.split(' ')[loggedInUserName.split(' ').length-1].charAt(0)  : loggedInUserName.charAt(0) ;
        if ((productId === UrlConstants.PRODUCT_ID_10 || productId === UrlConstants.PRODUCT_ID_11) && (mobileHeaderLogoImageLeftUrl || headerLogoLeftUrl)) {
            logo =
                <div className="cce-rightElement">
                { !isMobile && !toggleMenu && pageName !== 'estimate' &&
                    <div className="cce-title" style={{ paddingBottom: '0.7rem' }}>
                        <span className="cce-span">{activeScreen}</span>
                    </div>
                }
                <div>
                  <Button onClick={handleToggleMenu} style={{ padding: '0.2rem 1.2rem 0rem 0.2rem' }}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="60" width="60">
                         <circle cx="30" cy="30" r="20" stroke="#FFFFFF" stroke-width="3" fill="#4C555C" />
                                   <text x="30" y="35" style={{fontFamily: styles.globalFontFamily, fontSize: '14px' }} text-anchor="middle"  stroke="#FFFFFF"  stroke-width="1px"
                                         > {inital}
                                </text>
                            </svg>
                  </Button>
                  {toggleMenu &&  <div id="cce-menu">
                        {newEstimatePage && <ul   style={{ padding: '0rem 1.2rem 0rem 0.2rem' }}>
                             <li id="cce-menu-list">
                               <div id="cce-menu-left">
                               <svg xmlns="http://www.w3.org/2000/svg" height="60" width="60">
                                   <circle cx="30" cy="30" r="20" stroke="#4C555C" stroke-width="3" fill="#4C555C" />
                                       <text x="30" y="35"   text-anchor="middle"  stroke="white"  stroke-width="1px"
                                             > {inital}
                                       </text>
                                   </svg>
                                </div>
                                 <div id="cce-menu-right">
                                    <span class="cce-login-name">{loggedInUserName} </span>
                                    <span class="cce-login-email">{loggedInEmail}</span>
                                </div>
                            </li>
                            <li style={{ paddingTop: '1.36px', paddingBottom: '1.36px' }}>
                                <Button id="navigateManage" className="anchorTag-btn" onMouseDown={handleManageNavigation}
                                    onKeyPress={keyBoardManageEvent}>Estimate Management</Button>
                            </li>
                            <li style={{ paddingTop: '1.36px', paddingBottom: '1.36px' }}>
                                <Button id="navigateEstimate" className="anchorTag-btn" onMouseDown={handleNewEstimate}
                                    onKeyPress={keyBoardEstimateEvent}>New Estimate</Button>
                            </li>
                            <li style={{ paddingTop: '1.36px', paddingBottom: '1.36px' }}>
                                <Button id="navigateAdmin" className="anchorTag-btn" onMouseDown={handleAdminNavigation}
                                    onKeyPress={keyBoardAdminEvent}>Administration</Button>
                            </li>
                            <li><Button  id="logoutButton" className="anchorTag" onMouseDown={handleLogout} onKeyPress={keyBoardEvent}>Logout</Button></li>
                        </ul>
                        }
                        {!newEstimatePage && <ul   style={{ padding: '0rem 1.2rem 0rem 0.2rem' }}>
                             <li id="cce-menu-list">
                               <div id="cce-menu-left">
                               <svg xmlns="http://www.w3.org/2000/svg" height="60" width="60">
                                   <circle cx="30" cy="30" r="20" stroke="#4C555C" stroke-width="3" fill="#4C555C" />
                                       <text x="30" y="35"   text-anchor="middle"  stroke="white"  stroke-width="1px"
                                             > {inital}
                                       </text>
                                   </svg>
                                </div>
                                 <div id="cce-menu-right">
                                    <span class="cce-login-name">{loggedInUserName} </span>
                                    <span class="cce-login-email">{loggedInEmail}</span>
                                </div>
                            </li>
                            <li><NavLink to={'/products/' + productId + '/profiles/' + profileId + UrlConstants.MANAGE} > Estimate Management</NavLink>
                            </li>
                            <li><NavLink to={'/products/' + productId + '/profiles/' + profileId + UrlConstants.ESTIMATE} >New Estimate</NavLink>
                            </li>
                            <li><NavLink to={'/products/' + productId + '/profiles/' + profileId + UrlConstants.ADMIN} >Administration</NavLink></li>
                            <li><Button  id="logoutButton" className="anchorTag" onMouseDown={handleLogout}
                                onKeyPress={keyBoardEvent}>Logout</Button>
                            </li>
                       </ul> }</div>}
                    </div>
                </div >
        }
        return logo;
    }

    return (
        <div className="cce-header">
            <AppBar
                className={debug ? 'responsive-header app-header-debug' : 'responsive-header'}
                iconElementLeft={renderCCELeftElement()}
                iconStyleLeft={iconLeftStyle}
                iconElementRight={!isMobile ? renderCCERightElement() : newFunction1(open, displayMobileMenu, hamburgerMenu)}
                iconStyleRight={isMobile ? iconMobileRightStyle : iconRightStyle}
                iconClassNameRight='icon-right'
                style={{
                    verticalAlign: 'middle',
                    opacity: styles.headerOpacity,
                    background: `linear-gradient(${styles.headerBackgroundTopColor}, ${styles.headerBackgroundBottomColor})`,
                    height: styles.headerHeight,
                    marginTop: '1px !important',
                    textAlign: debugModeTextAlign,
                }}

            />
            { (companyLogo !== null || divisionLogo !== null) && <div className="header-logo-banner">
                { companyLogo !== null && <div className="company-logo">
                    <picture>
                        <img src={companyLogo} alt='Company Logo' className="logo-banner" />
                    </picture>
                </div>
                }
                { divisionLogo !== null && <div className="division-logo">
                    <picture>
                        <img src={divisionLogo} alt='Division Logo' className="logo-banner division" />
                    </picture>
                </div>
                }
            </div>
            }
        </div>
    );
};

CCEHeader.propTypes = {
    debug: PropTypes.bool.isRequired,
    images: PropTypes.object.isRequired,
    styles: PropTypes.object.isRequired,
    text: PropTypes.object,
};

export default CCEHeader;

function newFunction1(open, displayMobileMenu, hamburgerMenu) {
    return <div>
        {open ? displayMobileMenu() : ""}
        {hamburgerMenu()}
    </div>;
}

function whenPageNameEqualsEstimate(pageName) {
    return pageName === 'estimate' ? UrlConstants.NEW_ESTIMATE : UrlConstants.ADMINISTRATION;
}

function getIconMobileRightStyle(styles) {
    return {
        paddingTop: styles.headerLogoRightPaddingTop != null ? styles.headerLogoRightPaddingTop : "0px !important",
        paddingRight: styles.headerLogoRightPaddingRight != null ? styles.headerLogoRightPaddingRight : "0px !important",
        height: styles.headerLogoRightHeight != null ? styles.headerLogoRightHeight : "0px !important",
        width: styles.headerLogoRightWidth != null ? styles.headerLogoRightWidth : "0px !important",
        maxWidth: '400px',
        textAlign: 'right',
        marginTop: '22px',
        marginRight: '0px'
    };
}

function getIconRightStyle(styles) {
    return {
        paddingTop: styles.headerLogoRightPaddingTop != null ? styles.headerLogoRightPaddingTop : "0px !important",
        paddingRight: styles.headerLogoRightPaddingRight != null ? styles.headerLogoRightPaddingRight : "0px !important",
        height: styles.headerLogoRightHeight != null ? styles.headerLogoRightHeight : "0px !important",
        width: styles.headerLogoRightWidth != null ? styles.headerLogoRightWidth : "0px !important",
        maxWidth: 'inherit',
        textAlign: 'right',
        marginTop: '1.5rem',
    };
}

function getIconLeftStyle(styles) {
    return {
        paddingBottom: '0.2rem',
        marginTop: '0px !important',
        paddingLeft: styles.headerLogoLeftPaddingLeft != null ? styles.headerLogoLeftPaddingLeft : "0px !important",
        paddingTop: styles.headerLogoLeftPaddingTop != null ? styles.headerLogoLeftPaddingTop : "0px !important",
        height: styles.headerLogoLeftHeight != null ? styles.headerLogoLeftHeight : "0px !important",
        width: styles.headerLogoLeftWidth != null ? styles.headerLogoLeftWidth : "0px !important",
        marginLeft: '0px !important',
        maxWidth: '610px',
        textAlign: 'left',
    };
}
