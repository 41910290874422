import initialState from '../store';

export default function reducer(state = initialState, action = {}) {
    let display, text, styles, images, profileAccessControl, segmentTrackingCode;
    action.profile && (
        { display, text, styles, images, profileAccessControl, segmentTrackingCode } = action.profile
    );
    state = getFinalState(action, state, display, images, styles, text, {profileAccessControl, segmentTrackingCode});
    state = getFinalState1(action, state);
    state = getFinalState2(action, state);
    state = getFinalState3(action, state);
    state = getFinalState4(action, state);
    state = getFinalState5(action, state);
    state = getFinalState6(action, state);
    state = getFinalState7(action, state);
    return state;
}

function getFinalState7(action, state) {
    switch (action.type) {
        case 'RETURN_TO_ESTIMATE_DIALOG':
            return {
                ...state,
                returnToEstimateDialog: action.open,
                returnToEstimate: action.returnToEstimate,
                isCancelEstimate: action.cancel,
            };
        case 'INIT_TRACK_SEGMENT_EVENT':
            return {
                ...state,
                updatedFinalMap: new Map(),       
            }; 
        case 'UPDATE_SEGMENT_EVENT':
            state.updatedFinalMap.set(action.details.eventName,action.details.eventDetails);
            return {
                ...state,             
            }; 
        case 'CLEAR_SEGMENT_EVENT_MAP':
            state.updatedFinalMap.clear();
            return {
                ...state,
            };     
        case 'CANCEL_ANSWER_UPDATE':
            return {
                ...state,
                answerUpdated: initialState.answerUpdated,
                returnToEstimateDialog: initialState.returnToEstimateDialog,
                returnToEstimate: initialState.returnToEstimate,
            };
        case 'ATTEMPT_REPRICE_AND_EXPORT':
            return {
                ...state,
                isRepriceExport: true,
            };
        case 'REPRICE_AND_EXPORT_DIALOG_CLOSE':
            return {
                ...state,
                isRepriceExport: false,
                risks: initialState.risks,
                selectedAllRisk: initialState.selectedAllRisk,
                repriceInProgress: initialState.repriceInProgress,
                excludedRisks: initialState.excludedRisks,
            };
        case 'EXPORT_ESTIMATE':
            return {
                ...state,
                exportStatus: true,
            };
        case 'RESET_EXPORT_ESTIMATE':
            return {
                ...state,
                exportStatus: initialState.exportStatus,
            };
        case 'ATTEMPT_EXPORT':
            return {
                ...state,
                isExport: true,
            };
        case 'EXPORT_DIALOG_CLOSE':
            return {
                ...state,
                isExport: false,
                risks: initialState.risks,
                selectedAllRisk: initialState.selectedAllRisk,
                excludedRisks: initialState.excludedRisks,
            };
        case 'SELECTED_ALL_RISK':
            return {
                ...state,
                selectedAllRisk: true,
            };
        case 'REPRICE_EXPORT_STATUS':
            return {
                ...state,
                isRepriceExport: action.isRepriceExport,
                repriceInProgress: action.repriceInProgress,
            };
        case 'UPDATE_USER_GROUP_LOGO':
            return {
                ...state,
                userGroupDetail: {
                    ...state.userGroupDetail,
                    logo: action.logo
                }
            };
        case 'UPDATE_LOGGED_IN_LOGO':
            return {
                ...state,
                loggedInUserDetail: {
                    ...state.loggedInUserDetail,
                    companyLogo: action.companyLogo,
                    divisionLogo: action.divisionLogo,
                }
            };
        case 'UPDATE_LOGGED_IN_DISCLAIMER':
            return {
                ...state,
                loggedInUserDetail: {
                    ...state.loggedInUserDetail,
                    companyDisclaimer: action.companyDisclaimer,
                    divisionDisclaimer: action.divisionDisclaimer,
                }
            };
        case 'DELETE_ERROR':
            return {
                ...state,
                deleteError: action.errorCode
            };
        case 'EDIT_ESTIMATE':
            return {
                ...state,
                riskId: action.riskId,
                estimateID: action.estimateId,
                estimatePrice: action.estimatePrice,
                action: 'EDIT_ESTIMATE',
            };
        case 'REPRICE_ESTIMATE':
            return {
                ...state,
                riskId: action.riskId,
                estimateID: action.estimateId,
                activeIndex: action.activeIndex,
                action: 'REPRICE_ESTIMATE',
            };
        case 'RISK_DELETE':
            return {
                ...state,
                riskDetail: action.riskDetail,
                action: 'RISK_DELETE',
            };
        case 'ESTIMATE_DELETE':
            return {
                ...state,
                estimateDetail: action.estimateDetail,
                action: 'ESTIMATE_DELETE',
            };
        case 'RISK_EDIT':
            return {
                ...state,
                riskDetail: action.riskDetail,
                riskId: action.riskId,
                action: 'RISK_EDIT',
            };
        case 'CANCELLED_REPRICE_EXPORT_DIALOG':
            return {
                ...state,
                riskId: initialState.riskId,
                estimateID: initialState.estimateID,
                estimatePrice: initialState.estimatePrice,
                action: initialState.action,
                activeIndex: initialState.activeIndex,
                riskDetail: initialState.riskDetail,
                estimateDetail: initialState.estimateDetail,
            };
        case 'SET_REPRICE_AND_EXPORT':
            return {
                ...state,
                action: 'REPRICE_EXPORT',
            };
        case 'CHANGE_USER_FULL_NAME':
            return {
                ...state,
                userCapturedDetail: {
                    ...state.userCapturedDetail,
                    fullName: action.data,
                    error: initialState.error,
                },
            };
        case 'CHANGE_USER_EMAIL_ID':
            return {
                ...state,
                userCapturedDetail: {
                    ...state.userCapturedDetail,
                    emailAddress: action.data,
                    error: initialState.error,
                },
            };
        case 'USER_CAPTURE_VALIDATION_ERROR':
            return {
                ...state,
                userCapturedDetail: {
                    ...state.userCapturedDetail,
                    error: action.data,
                },
            };
        case 'SELECT_TABLE_EXPORT':
            return {
                ...state,
                uploadExportTableType: action.selectedTableExport,
            };
            case 'REPORTS_CHANGE':
                return {
                    ...state,
                    isUsageReport: action.loggedInUserDetail.roleTypeId === 1 ? true : false,
                    selectReportCategoryType: 'select category',
                };
            case 'SELECT_CATEGORY_TYPE':
                return {
                    ...state,
                    selectedReportCategoryType: action.selectedCategoryType,
                };
            case 'SELECT_COMPANY_USAGE':
                return {
                    ...state,
                    selectedCompanyUsage: action.selectedCompanyUsage,
                };
            case 'SELECT_DIVISION_USAGE':
                return {
                    ...state,
                    selectedDivisionUsage: action.selectedDivisionUsage,
                };
            case 'SELECT_TEAM_USAGE':
                return {
                    ...state,
                    selectedTeamUsage: action.selectedTeamUsage,
                };
            case 'SELECT_USAGE_START_DATE':
                return {
                    ...state,
                    usageStartDate: action.usageStartDate,
                };
            case 'SELECT_USAGE_END_DATE':
                return {
                    ...state,
                    usageEndDate: action.usageEndDate,
                };
            case 'RESET_USAGE_VALUES':
                return {
                    ...state,
                    selectedReportCategoryType: null,
                    selectedCompanyUsage: null,
                    selectedDivisionUsage: null,
                    selectedTeamUsage: null,
                    usageStartDate: null,
                    usageEndDate: null,
    
                };
            case 'ESTIMATE_DIRECT_STRATA':
                return {
                    ...state,
                    isEstimateDirect: true,
    
                };            
        default:
            return state;
    }
}

function getFinalState6(action, state) {
    switch (action.type) {
        case 'ATTEMPT_LOGOUT':
            return {
                ...state,
                isLogout: true,
                claudtoken: {
                    access_token: null,
                    refresh_token: null,
                    expires_in: null,
                    validToken: false,
                    error: false,
                    message: action.data,
                    apigee_token: null,
                },
                tokenContents: {},
                code: null,
                loginLoading: false,
                loggedInUserDetail: {
                    id: null,
                    userName: null,
                    userGroupId: null,
                    updatedBy: null,
                    fullName: null,
                    emailId: null,
                    active: null,
                    roleTypeId: null,
                    userGuid: null,
                    clAppAcctUserGuid: null,
                    companyGroupId: null,
                },
                billingToken: {
                    billing_token: null,
                },
            };
        case 'ATTEMPT_FETCH_TOKEN':
            return {
                ...state,
                claudtoken: {
                    access_token: null,
                    refresh_token: null,
                    expires_in: null,
                    validToken: false,
                    error: false,
                    apigee_token: null,
                    message: null,
                },
                code: action.data,
                loginLoading: true,
                loggedInUserDetail: {
                    id: null,
                    userName: null,
                    userGroupId: null,
                    updatedBy: null,
                    fullName: null,
                    emailId: null,
                    active: null,
                    roleTypeId: null,
                    userGuid: null,
                    clAppAcctUserGuid: null,
                    companyGroupId: null,
                },
                billingToken: {
                    billing_token: null,
                },
            };

        case 'UPDATE_PROFILE_PRODUCT_ID':
            return {
                ...state,
                productId: action.productId,
                profileId: action.profileId,
            };
        case 'SUCCEED_FETCH_TOKEN':
            return {
                ...state,
                claudtoken: {
                    access_token: action.data.response.access_token,
                    refresh_token: action.data.response.refresh_token,
                    expires_in: action.data.response.expires_in,
                    validToken: true,
                    error: null,
                    apigee_token: action.data.response.apigeeAccessToken,
                    message: getClaudTokenMessage(state),
                },
                tokenContents: {
                    ...action.data.tokenContents,
                },
                loginLoading: false,
                tokenStatus: "",
            };
        case 'FAIL_FETCH_TOKEN':
            return {
                ...state,
                claudtoken: {
                    access_token: null,
                    refresh_token: null,
                    expires_in: null,
                    validToken: false,
                    error: action.data,
                    apigee_token: null,
                    message: null,
                },
                code: null,
                loginLoading: false,
                tokenStatus: "",
                loggedInUserDetail: {
                    id: null,
                    userName: null,
                    userGroupId: null,
                    updatedBy: null,
                    fullName: null,
                    emailId: null,
                    active: null,
                    roleTypeId: null,
                    userGuid: null,
                    clAppAcctUserGuid: null,
                    companyGroupId: null
                },
                billingToken: {
                    billing_token: null,
                },
            };
        case 'RESET_TOKEN_DETAILS':
            return {
                ...state,
                claudtoken: {
                    access_token: null,
                    refresh_token: null,
                    expires_in: null,
                    validToken: false,
                    error: null,
                    apigee_token: null,
                    message: null,
                },
                code: null,
                loginLoading: false,
                tokenContents: {},
                isLogout: false,
                tokenStatus: "",
                loggedInUserDetail: {
                    id: null,
                    userName: null,
                    userGroupId: null,
                    updatedBy: null,
                    fullName: null,
                    emailId: null,
                    active: null,
                    roleTypeId: null,
                    userGuid: null,
                    clAppAcctUserGuid: null,
                    companyGroupId: null
                },
                billingToken: {
                    billing_token: null,
                },
            };
        case 'ATTEMPT_SAVE_CODE':
            return {
                ...state,
                code: action.data,
                tokenStatus: "",
            };
        case 'TOKEN_EXPIRED':
            return {
                ...state,
                tokenStatus: action.data,
                claudtoken: {
                    ...state.claudtoken,
                    message: action.data === "TOKEN NOT EXPIRED" ? null : state.claudtoken.message,
                },
            };
        case 'UPDATE_HOMEPATHNAME':
            return {
                ...state,
                homePath: action.data,
            };
        case 'SET_POSTCODE':
            return {
                ...state,
                postcode: action.postcode,
                riskDetail: {
                    ...state.riskDetail,
                    postcode: action.postcode,
                }
            };
        case 'FETCH_LOGGED_IN_USER':
            return {
                ...state,
                loggedInUserDetail: {
                    ...state.loggedInUserDetail,
                    id: action.data.id,
                    userName: action.data.userName,
                    fullName: action.data.fullName,
                    emailId: action.data.emailId,
                    userGuid: action.data.userGuid,
                    clAppAcctUserGuid: action.data.clAppAcctUserGuid,
                    userGroupId: action.data.userGroupId,
                    roleTypeId: action.data.roleTypeId,
                    companyLogo: action.data.companyLogo,
                    divisionLogo: action.data.divisionLogo,
                    companyDisclaimer: action.data.companyDisclaimer,
                    divisionDisclaimer: action.data.divisionDisclaimer,
                    companyGroupId: action.data.companyGroupId,
                },
                userId: action.data.id,
            };
        case 'UPDATE_ASSIGNED_NEW_RISK':
            return {
                ...state,
                riskDetail: {
                    ...state.riskDetail,
                    address: null,
                    propertyId: null,
                    postcode: null,
                    assignedTo: action.loggedInUserId,
                },
                cceImages: initialState.cceImages,
                addressChosen: initialState.addressChosen,
                errorPropertyMsg: initialState.errorPropertyMsg,
                postcodeErrorMsg: initialState.postcodeErrorMsg,
                answerUpdated: initialState.answerUpdated,
            };
        case 'UPDATE_APIGEE_TOKEN':
            return {
                ...state,
                claudtoken: {
                    ...state.claudtoken,
                    error: null,
                    apigee_token: action.apigeeAccessToken,
                },
            };
        case 'FAIL_APIGEE_TOKEN':
            return {
                ...state,
                claudtoken: {
                    ...state.claudtoken,
                    error: action.error,
                    apigee_token: null
                },
            };
        case 'SUCCEED_FETCH_BILLING_TOKEN':
            return {
                ...state,
                billingToken: {
                    billing_token: action.billingAccessToken,
                },
            };
        case 'FAIL_FETCH_BILLING_TOKEN':
            return {
                ...state,
                billingToken: {
                    billing_token: null,
                },
            };
        case 'NEW_ESTIMATE_CLICKED':
            return {
                ...state,
                cceImages: initialState.cceImages,
                postcode: null,
                riskDetail: initialState.riskDetail,
            };
        case 'RESET_ADD_EDIT_ESTIMATE':
            return {
                ...state,
                buildingGrp: [],
                estimatePrice: null,
                showResult: false,
                isIncompleteEstimate: true,
                inCompleteEstimate: {
                    loading: false,
                },
                isBuildingLoading: false,
                imageUploads: null,
                imageDetail: {
                    imageDescriptionDetail: [],
                    errorMessagee: null,
                    imageDesc: null,
                },
                errors: [],
                postcode: null,
                depdencyErrors: [],
                currentTab: initialState.currentTab,
            };
        case 'NEW_RISK':
            return {
                ...state,
                addressChosen: initialState.addressChosen,
                questions: initialState.questions,
                result: initialState.result,
                estimateID: initialState.estimateID,
                suggestions: initialState.suggestions,
                riskId: initialState.riskId,
                isEditRisk: initialState.isEditRisk,
                riskDetail: {
                    policyNumber: null,
                    insured: null,
                    underwriter: null,
                    lastValuation: null,
                    assignedTo: getAssignedToWhenActionTypeisNewRisk(state),
                    address: null,
                    propertyId: null,
                    postcode: null
                },
                isEditEstimate: initialState.isEditEstimate,
                isEmailDownload: initialState.isEmailDownload,
                cceImages: initialState.cceImages,
            };
        case 'VALIDATION_BUILDING_ERROR':
            return {
                ...state,
                depdencyErrors: action.errors,
            };
        case 'UPDATE_FETCH_QUESTIONS':
            return {
                ...state,
                questions: {
                    ...state.questions,
                    data: action.data,
                },
            };
        case 'OFF_SEARCH_LOADING_ON_ERROR':
            return {
                ...state,
                searchResult: {
                    ...state.searchResult,
                    isLoading: false,
                }
            };
        case 'SET_CURRENT_TAB':
            return {
                ...state,
                currentTab: action.currentTab
            };
        case 'PROPERTY_ID_MESSAGE_SHOW':
            return {
                ...state,
                isPropertyIdTriggered: action.data,
            };
        case 'POSTCODE_CHANGED':
            return {
                ...state,
                addressChosen: {
                    suggestion: action.address,
                    propertyId: action.propertyId,
                },
                riskDetail: {
                    ...state.riskDetail,
                    propertyId: action.propertyId,
                },
                postcodeErrorMsg: action.error,
            };
        case 'POSTCODE_MESSAGE_SHOW':
            return {
                ...state,
                isPostcodeLocked: action.data,
            };
        case 'UPDATE_PROPERTY_ERROR':
            return {
                ...state,
                errorPropertyMsg: action.message,
            };
        case 'ADDED_RISK':
            return {
                ...state,
                risks: [...state.risks, action.data],
                excludedRisks: state.excludedRisks.filter(item => item !== action.data),
            };
        case 'RISK_REMOVED':
            return {
                ...state,
                risks: state.risks.filter(item => item !== action.data),
                excludedRisks: [...state.excludedRisks, action.data],
            };
        case 'RISK_ALL_REMOVED':
            return {
                ...state,
                risks: initialState.risks,
                excludedRisks: initialState.excludedRisks,
                selectedAllRisk: initialState.selectedAllRisk,
            };
        default:
            return state;
    }
}

function getFinalState5(action, state) {
    switch (action.type) {
        case 'BEFORE_REFRESH_USER_LIST':
            return {
                ...state,
                isUserListing: {
                    loading: true,
                },
            };
        case 'OFF_USER_LOADING_ON_ERROR':
            return {
                ...state,
                isUserListing: {
                    loading: false,
                },
            };
        case 'USER_GROUP_DELETE_DIALOG':
            return {
                ...state,
                deleteGroup: {
                    open: action.data,
                    deleteUserGroupDetail: action.deleteUserGroupDetail,
                    refresh: action.refresh,
                },
                deleteError: initialState.deleteError,
            };
        case 'USER_DELETE_DIALOG':
            return {
                ...state,
                deleteUser: {
                    open: action.data,
                    deleteUserDetail: action.deleteUserDetail,
                    refresh: action.refresh,
                    hasMultipleAccount: action.hasMultipleAccount,
                },
                deleteError: initialState.deleteError,
            };
        case 'DELETED_USER_GROUP':
            return {
                ...state,
                deleteGroup: initialState.deleteGroup,
                deleteError: initialState.deleteError,
            };
        case 'DELETED_USER':
            return {
                ...state,
                deleteUser: initialState.deleteUser,
                deleteError: initialState.deleteError,
            };
        case 'EDIT_USER_DETAIL_CLICKED':
            return {
                ...state,
                isEditUserEnabled: true,
                isFilterUserEnabled: false,
                editUserId: action.editUserId,
            };
        case 'ON_CHANGE_EDIT_USER_NAME':
            return {
                ...state,
                editUserDetail: {
                    ...state.editUserDetail,
                    userName: action.editUserName,
                    errorMsg: null,
                    successMsg: null,
                }
            };

        case 'ON_CHANGE_EDIT_USER_STATUS':
            return {
                ...state,
                editUserDetail: {
                    ...state.editUserDetail,
                    active: action.editUserStatus,
                    errorMsg: null,
                    successMsg: null,
                }
            };   
        case 'ON_CHANGE_EDIT_FULL_NAME':
            return {
                ...state,
                editUserDetail: {
                    ...state.editUserDetail,
                    fullName: action.editFullName,
                    errorMsg: null,
                    successMsg: null,
                }
            };
        case 'ON_CHANGE_EDIT_EMAIL_ID':
            return {
                ...state,
                editUserDetail: {
                    ...state.editUserDetail,
                    emailId: action.editEmailId,
                    successMsg: null,
                    errorMsg: null,
                }
            };
        case 'UPDATE_USER_STATUS':
            return {
                ...state,
                editUserDetail: {
                    ...state.editUserDetail,
                    successMsg: action.successMsg,
                    errorMsg: action.errorMsg,
                }
            };
        case 'CREATE_USER_STATUS':
            return {
                ...state,
                userDetail: {
                    ...state.userDetail,
                    successMsg: action.successMsg,
                    errorMsg: action.errorMsg,
                },
                isAddUserGroup: {
                    loading: false,
                },
            };

        case 'UPDATE_USER_EXISTS':
            return {
                ...state,
                userDetail: {
                    ...state.userDetail,
                    userValidationMsg: action.userValidationMsg,
                }
            };
        case 'UPDATE_EMAIL_EXISTS':
            return {
                ...state,
                userDetail: {
                    ...state.userDetail,
                    emailValidationMsg: action.emailValidationMsg,
                }
            };

        case 'UPDATE_USER_DETAILS':
            return {
                ...state,
                userDetail: {
                    ...state.userDetail,
                    userId: action.userId,
                    useremailId: action.emailId,
                    userfullname: action.fullName,
                    isUserNameSearch: action.isUserNameSearch,
                },
                existingUser: true,
            };

        case 'EXISTING_USER_DIALOG':
            return {
                ...state,
                existingUser: false,
            };

        case 'CANCEL_EXISTING_USER':
            return {
                ...state,
                userDetail: initialState.userDetail,
                existingUser: false,
            };

        case 'ON_CHANGE_EDIT_USER_GROUP':
            return {
                ...state,
                editCompany: 0,
                editDivision: 0,
                editTeam: 0,
                editUserDetail: {
                    ...state.editUserDetail,
                    roleTypeId: action.editRoleTypeId,
                    successMsg: null,
                    errorMsg: null,
                }
            };
        case 'ON_CHANGE_EDIT_COMPANY':
            return {
                ...state,
                editCompany: action.editCompany,
                editDivision: 0,
                editTeam: 0,
                editUserDetail: {
                    ...state.editUserDetail,
                    successMsg: null,
                    errorMsg: null,
                }
            };
        case 'ON_CHANGE_EDIT_DIVISION':
            return {
                ...state,
                editDivision: action.editDivision,
                editTeam: 0,
                editUserDetail: {
                    ...state.editUserDetail,
                    successMsg: null,
                    errorMsg: null,
                }
            };
        case 'ON_CHANGE_EDIT_TEAM':
            return {
                ...state,
                editTeam: action.editTeam,
                editUserDetail: {
                    ...state.editUserDetail,
                    successMsg: null,
                    errorMsg: null,
                }
            };
        case 'USER_BACK_BUTTON_CLICKED':
            return {
                ...state,
                isEditUserEnabled: false,
                isFilterUserEnabled: true,
                editUserId: null,
                existingUser: false,
            };
        case 'IS_ADMIN_USER':
            return {
                ...state,
                isAdminUser: action.data,
            };
        case 'USER_GROUP_ROLE':
            return {
                ...state,
                loggedInUserGroupRole: action.data.loggedInUserGroupRole,
            };
        case 'USER_BACK_BUTTON':
            return {
                ...state,
                isFilterUserEnabled: true,
                isAddUserEnabled: false,
                userDetail: initialState.userDetail,
                userGroupOption: "select",
            };
        case 'USER_GROUP_BACK_BUTTON':
            return {
                ...state,
                isFilterEnabled: true,
                isAddGroupEnabled: false,
            };
        case 'FETCHED_USER_DETAIL':
            return {
                ...state,
                editUserDetail: {
                    ...state.editUserDetail,
                    id: action.data.id,
                    userName: action.data.userName,
                    userGroupId: action.data.userGroupId,
                    updatedBy: action.data.updatedBy,
                    fullName: action.data.fullName,
                    emailId: action.data.emailId,
                    active: action.data.active,
                    roleTypeId: action.data.roleTypeId,
                    errorMsg: null,
                    successMsg: null,
                },
                editCompany: action.data.companyGroupId,
                editDivision: action.data.divisionGroupId,
                editTeam: action.data.teamGroupId,
            };
        case 'FETCHED_USER_LIST':
            return {
                ...state,
                userList: {
                    ...state.userList,
                    data: action.userList,
                    error: null,
                },
                isUserListing: {
                    loading: false,
                },
            };
        default:
            return state;    
    }
}

function getFinalState4(action, state) {
    switch (action.type) {
        case 'ON_CHANGE_USER_FULL_NAME':
            return {
                ...state,
                userDetail: {
                    userfullname: action.userfullname,
                    userId: state.userDetail.userId,
                    useremailId: state.userDetail.useremailId,
                }
            };
        case 'ON_CHANGE_USER_EMAIL_ID':
            return {
                ...state,
                userDetail: {
                    useremailId: action.useremailId,
                    userfullname: state.userDetail.userfullname,
                    userId: state.userDetail.userId,
                    emailValidationMsg: null,
                }
            };
        case 'UPDATE_SELECTED_TEAM':
            return {
                ...state,
                selectedTeam: action.team,
            };


        case 'ON_CHANGE_DIVISION_NAME':
            return {
                ...state,
                divisionName: action.divisionName,
            };
        case 'UPDATE_USER_GROUPS':
            return {
                ...state,
                userGroups: {
                    ...state.userGroups,
                    data: action.data.cceUserGroupsResponse,
                },
            };
        case 'UPDATE_ROLE_TYPES':
            return {
                ...state,
                roleType: {
                    ...state.roleType,
                    data: action.data.cceRoleResponse,
                },
            };
        case 'UPDATE_SELECTED_COMPANY':
            return {
                ...state,
                selectedCompany: action.company,
                userGroupDetail: {
                    ...state.userGroupDetail,
                    companyId: action.company.company,
                }
            };
        case 'UPDATE_GROUP_POLICY':
            return {
                ...state,
                userGroupDetail: {
                    ...state.userGroupDetail,
                    groupSharePolicy: action.policy,
                }
            };
        case 'UPDATE_SELECTED_DIVISION':
            return {
                ...state,
                selectedDivision: action.division,
                userGroupDetail: {
                    ...state.userGroupDetail,
                    divisionId: action.division.division,
                }
            };
        case 'CLEAR_DATA':
            return {
                ...state,
                companyName: null,
                teamName: null,
                divisionName: null,
                disclaimer: null,
                selectedCompany: null,
                selectedDivision: null,
                userGroupDetail: {
                    ...state.userGroupDetail,
                    logo: null,
                    groupSharePolicy: null,
                },
            };
        case 'CLEAR_DATA_USER':
            return {
                ...state,
                userDetail: {
                    ...state.userDetail,
                    userId: null,
                    useremailId: null,
                    userfullname: null,
                },
                selectedTeam: null,
                selectedCompany: null,
                selectedDivision: null,
                errorUserLevelMsg: null,
                errorCompanyMsg: null,
                errorDivisionMsg: null,
                errorTeamMsg: null,
            };
        case 'BEFORE_SAVE_USER_GROUP':
            return {
                ...state,
                isAddUserGroup: {
                    loading: true,
                },
            };
        case 'RESET_UPDATE_STATUS':
            return {
                ...state,
                editUserDetail: {
                    ...state.editUserDetail,
                    errorMsg: null,
                    successMsg: null
                },
            };
        case 'AFTER_SAVE_USER_GROUP':
            return {
                ...state,
                isAddUserGroup: {
                    loading: false,
                },
            };
        case 'TAB_CHANGE':
            return {
                ...state,
                isAddGroupEnabled: false,
                isFilterEnabled: true
            };

        case 'IMPORT_RISKS_CHANGE':
            return {
                ...state,
                isUserGroupOptionSystem: action.loggedInUserDetail.roleTypeId === 1 ? true : false,
                uploadExportTableType: 'riskEstimateTable',
            };

        case 'UPDATE_GROUP_SEARCH_RESULT':
            return {
                ...state,
                userGroupSearchResult: getUserGroupSearchResultForUpdateGroupSearchResult(state, action),
                userGroupId: initialState.userGroupId,
                grpPagination: {
                    activePage: state.grpPagination.activePage,
                    totalPages: getTotalPagesForUpdateGroupSearchResult(action),
                },
                isUserGroupListing: {
                    loading: false,
                },
            };
        case 'BEFORE_REFRESH_USER_GROUP_LIST':
            return {
                ...state,
                isUserGroupListing: {
                    loading: true,
                },
            };
        case 'OFF_GROUP_LOADING_ON_ERROR':
            return {
                ...state,
                isUserGroupListing: {
                    loading: false,
                },
            };
        case 'UPDATE_USER_GROUP_DETAIL':
            return {
                ...state,
                userGroupDetail: {
                    ...state.userGroupDetail,
                    userGroupOption: action.data.userGroup,
                    selectedCompanyName: action.data.selectedCompanyName,
                    companyId: action.data.companyId,
                    divisionId: action.data.divisionId,
                    selectedDivisionName: action.data.selectedDivisionName,
                    teamMemberName: action.data.teamMemberName,
                    teamMemberId: action.data.teamMemberId,
                    teamName: action.data.teamName,
                    teamId: action.data.teamId,
                    disclaimer: action.data.footerText,
                    logo: action.data.logo,
                    systemId: action.data.systemId,
                    groupSharePolicy: action.data.groupSharePolicy
                },
                userGroupOption: action.data.userGroup,
                selectedCompany: {
                    company: action.data.companyId,
                },
                selectedDivision: {
                    division: action.data.divisionId,
                },
                divisionName: action.data.selectedDivisionName,
                teamName: action.data.teamName,
                companyName: action.data.selectedCompanyName,
                disclaimer: action.data.footerText,
            };
        case 'EDIT_USER_GROUP_CLICKED':
            return {
                ...state,
                isEditUserGroup: true,
                isAddGroupEnabled: true,
                isFilterEnabled: false,
            };
        case 'USER_TAB_CHANGE':
            return {
                ...state,
                isAddUserEnabled: false,
                isFilterUserEnabled: true,
                isEditUserEnabled: false,
                userGroupOption: "select",
            };
        case 'CLEAR_USER_FILTER':
            return {
                ...state,
                userSearchParams: {
                    ...state.userSearchParams,
                    searchBy: "userName",
                    searchText: "",
                },
                userPagination: {
                    activePage: 1,
                    totalPages: state.userPagination.totalPages,
                },
                userSortParams: {
                    sortBy: "userName",
                    sortOrder: "descending",
                }
            };
        case 'USER_MNG_SEARCH_BY':
            return {
                ...state,
                userSearchParams: {
                    ...state.userSearchParams,
                    searchBy: action.searchBy,
                },
                userPagination: {
                    activePage: 1,
                    totalPages: state.userPagination.totalPages,
                },
            };
        case 'USER_MNG_SEARCH_TEXT':
            return {
                ...state,
                userSearchParams: {
                    ...state.userSearchParams,
                    searchText: action.searchText,
                },
                userPagination: {
                    activePage: 1,
                    totalPages: state.userPagination.totalPages,
                },
            };
        case 'ADD_USER_ENABLED':
            return {
                ...state,
                isAddUserEnabled: action.isAddUser,
                userDetail: initialState.userDetail,
                isFilterUserEnabled: false,
                existingUser: false
            };

        case 'PAGE_NO_UPDATE_USER':
            return {
                ...state,
                userPagination: {
                    activePage: action.pageNo,
                    totalPages: state.userPagination.totalPages,
                }
            };
        case 'UPDATE_USER_SEARCH_RESULT':
            return {
                ...state,
                userSearchResult: getUserSearchResultForUpdateUserSearchResult(state, action),
                userPagination: {
                    activePage: state.userPagination.activePage,
                    totalPages: getTotalPagesForUpdateUserSearchResult(action),
                },
                isUserListing: {
                    loading: false,
                },
                existingUser: false,
            };
        default:
            return state;
    }
}

function getFinalState3(action, state) {
    switch (action.type) {
        case 'EDIT_ESTIMATE_CLICKED':
            return {
                ...state,
                isEditEstimate: true,
                riskId: action.riskId,
                isFirstEditEstimate: true,
                errorPropertyMsg: initialState.errorPropertyMsg,
            };
        case 'NEW_ESTIMATE_ON_EDIT':
            return {
                ...state,
                isFirstEditEstimate: false,
            };
        case 'UPDATE_ESTIMATE_PREVIEW':
            return {
                ...state,
                searchResult: {
                    ...state.searchResult,
                    data: state.searchResult.data,
                    estimateResult: action.data.cceEstimateLogDetailsList,
                    error: null,
                    isOpen: true,
                    riskId: action.riskId
                },
                cceImages: {
                    data: getCCEImagesDataForEstimatePreview(action),
                },
                activeIndex: action.activeIndex,
                addressChosen: {
                    suggestion: action.data.address,
                    propertyId: action.data.propertyId,
                },
                riskDetail: {
                    ...state.riskDetail,
                    propertyId: action.data.propertyId,
                    postcode: action.data.postcode,
                },
                postcode: action.data.postcode,
                activeRiskId: action.riskId,
            };
        case 'SORT_BASED_ON_DATE':
            return {
                ...state,
                sortParams: {
                    dateChange: action.dateChange,
                    sortOrder: state.sortParams.sortOrder
                }
            };
        case 'SORTING_ORDER':
            return {
                ...state,
                sortParams: {
                    sortOrder: action.sortOrder,
                    dateChange: state.sortParams.dateChange,
                },
            };
        case 'EDIT_RISK_CLICKED':
            return {
                ...state,
                questions: initialState.questions,
                riskDetail: {
                    policyNumber: action.data.policyNumber,
                    insured: action.data.insured,
                    underwriter: action.data.underwriter,
                    lastValuation: action.lastValuation,
                    assignedTo: action.data.assignedTo,
                    address: action.data.address,
                    propertyId: action.data.propertyId,
                    postcode: action.data.postcode,
                },
                riskId: action.riskId,
                isEditRisk: true,
                errorPropertyMsg: initialState.errorPropertyMsg,
            };
        case 'RISK_UPDATED':
            return {
                ...state,
                riskId: initialState.riskId,
                isEditRisk: initialState.isEditRisk,
                estimateID: initialState.estimateID,
            };
        case 'RISK_ADD_UPDATED':
            return {
                ...state,
                riskId: action.riskId,
            };

        case 'PAGE_NO_UPDATE':
            return {
                ...state,
                pagination: {
                    activePage: action.pageNo,
                    totalPages: state.pagination.totalPages,
                }
            };

        case 'PAGE_NO_UPDATE_GROUP':
            return {
                ...state,
                grpPagination: {
                    activePage: action.pageNo,
                    totalPages: state.grpPagination.totalPages,
                }
            };
        case 'REPRICED_ESTIMATE':
            return {
                ...state,
                searchResult: {
                    ...state.searchResult,
                    isLoading: false,
                }
            };
        case 'REPRICED_ESTIMATE_FLAG':
            return {
                ...state,
                isEstimateRepriced: true,
            };
        case 'CANCELLED_EDIT':
            return {
                ...state,
                isEditRisk: initialState.isEditRisk,
                isEditEstimate: initialState.isEditEstimate,
                isEmailDownload: initialState.isEmailDownload,
            };
        case 'RISK_DETAIL_DIALOG':
            return {
                ...state,
                deleteRisk: getDeleteRiskForRiskDetailDialog(action, state),
                isRiskOrEstimate: "Risk"
            };
        case 'DELETE_RISK':
            return {
                ...state,
                deleteRisk: getDeleteRiskForDeleteRisk(action, state),
                isRiskOrEstimate: state.isRiskOrEstimate
            };
        case 'ESTIMATE_DETAIL_DIALOG':
            return {
                ...state,
                deleteEstimate: getDeleteEstimateForEstimateDetailDialog(action, state),
                isRiskOrEstimate: "Estimate"
            };
        case 'DELETE_ESTIMATE':
            return {
                ...state,
                deleteEstimate: getDeleteEstimateForActionTypeDeleteEstimate(action, state),
                isRiskOrEstimate: state.isRiskOrEstimate
            };
        case 'ATTEMPT_REPRICE_OR_EMAIL_ESTIMATE':
            return {
                ...state,
                searchResult: {
                    ...state.searchResult,
                    isLoading: true,
                },
                isEstimateRepriced: false
            };
        case 'SAVE_INCOMPLETE_ESTIMATE':
            return {
                ...state,
                inCompleteEstimate: {
                    loading: true,
                },
                answerUpdated: initialState.answerUpdated,
                returnToEstimate: initialState.returnToEstimate,
                returnToEstimateDialog: initialState.returnToEstimateDialog,
            };
        case 'SAVED_INCOMPLETE_ESTIMATE':
            return {
                ...state,
                inCompleteEstimate: {
                    loading: false,
                },
                answerUpdated: initialState.answerUpdated,
                returnToEstimate: initialState.returnToEstimate,
                returnToEstimateDialog: initialState.returnToEstimateDialog,
            };
        case 'ESTIMATE_PREVIEW_MOBILE':
            return {
                ...state,
                searchResult: {
                    ...state.searchResult,
                    openPreviewDialog: action.data,
                }
            };
        case 'ESTIMATE_PREVIEW_CLOSE':
            return {
                ...state,
                searchResult: {
                    ...state.searchResult,
                    openPreviewDialog: false,
                }
            };
        case 'EMAIL_DOWNLOAD_REPORT':
            return {
                ...state,
                isEditEstimate: false,
                isEmailDownload: true,
                isIncompleteEstimate: false,
                searchResult: {
                    ...state.searchResult,
                    isLoading: false,
                }
            };
        case 'USER_GROUP_OPTION':
            return {
                ...state,
                userGroupOption: action.userGroupOption,
            };
        case 'ON_CHANGE_COMPANY_NAME':
            return {
                ...state,
                companyName: action.companyName,
            };
        case 'ON_CHANGE_COMPANY_DISCLAIMER':
            return {
                ...state,
                disclaimer: action.disclaimer,
            };
        case 'ON_CHANGE_TEAM_NAME':
            return {
                ...state,
                teamName: action.teamName,
            };
        case 'ON_CHANGE_USER_ID':
            return {
                ...state,
                userDetail: {
                    ...state.userDetail,
                    successMsg: null,
                    userValidationMsg: null,
                    errorMsg: null,
                    userId: action.userId,
                }
            };
        default:
            return state;
    }
}

function getFinalState2(action, state) {
    switch (action.type) {
        case 'SUCCEED_CONTACT_US':
            return {
                ...state,
                contactUs: {
                    ...state.contactUs,
                    loaded: action.data,
                },
            };
        case 'UPLOAD_IMAGE':
            return {
                ...state,
                imageUploads: {
                    ...state.imageUploads,
                    loaded: action.data,
                },
            };
        case 'POLICY_NUMBER_PICKED':
            return {
                ...state,
                riskDetail: {
                    ...state.riskDetail,
                    policyNumber: action.policyNumber,
                },
            };
        case 'INSURED_PICKED':
            return {
                ...state,
                riskDetail: {
                    ...state.riskDetail,
                    insured: action.insured,
                },
            };
        case 'UNDERWRITER_PICKED':
            return {
                ...state,
                riskDetail: {
                    ...state.riskDetail,
                    underwriter: action.underwriter,
                },
            };
        case 'STRATA_PLAN_NUMBER_PICKED':
            return {
                ...state,
                riskDetail: {
                    ...state.riskDetail,
                    strataPlanNumber: action.strataPlanNumber,
                },
            };
        case 'ASSIGNED_TO_PICKED':
            return {
                ...state,
                riskDetail: {
                    ...state.riskDetail,
                    assignedTo: action.assignedTo.assignedTo,
                },
            };
        case 'LAST_VALUATION_PICKED':
            return {
                ...state,
                riskDetail: {
                    ...state.riskDetail,
                    lastValuation: action.lastValuation,
                },
            };
        case 'NEW_BUILDING_ADDED':
            return {
                ...state,
                result: initialState.result,
                expandedIndex: state.buildingGrp.length,
                buildingGrp: [...state.buildingGrp, action.data],
                expanded: true,
                questions: {
                    ...state.questions,
                    loading: false,
                },
                isBuildingLoading: false,
                answerUpdated: true,
            };
        case 'RISKID_UPDATED':
            return {
                ...state,
                riskId: action.riskId,
                activeIndex: state.activeIndex + 1,
                answerUpdated: initialState.answerUpdated,
            };
        case 'BUILDING_REMOVED':
            return {
                ...state,
                result: initialState.result,
                buildingGrp: state.buildingGrp.slice(0, action.data).concat(state.buildingGrp.slice(action.data + 1, state.buildingGrp.length)),
                expandedIndex: state.buildingGrp.length - 2,
            };
        case 'COLLAPSE_PANEL_UPDATED':
            return {
                ...state,
                expandedIndex: action.expandedIndex,
                expanded: getExpandedWhenActionTypeIsCollapsePAnelUpdated(action, state),
            };
        case 'WIDTH_CHANGE':
            return {
                ...state,
                widthChange: action.widthChange,
            };
        case 'RISKID_MESSAGE_SHOW':
            return {
                ...state,
                isRiskIdTriggered: action.data,
            };
        case 'RISKID_MESSAGE_HIDE':
            return {
                ...state,
                isRiskIdTriggered: false,
                isPropertyIdTriggered: false,
                isPostcodeLocked: false,
            };
        case 'UPDATE_SEARCH_RESULT':
            return {
                ...state,
                searchResult: {
                    ...state.searchResult,
                    data: action.searchResults.riskDetailsResponseList,
                    openPreviewDialog: false,
                    error: null,
                },
                addressChosen: initialState.addressChosen,
                questions: initialState.questions,
                result: initialState.result,
                suggestions: initialState.suggestions,
                riskId: initialState.riskId,
                isEditRisk: initialState.isEditRisk,
                riskDetail: initialState.riskDetail,
                isEditEstimate: initialState.isEditEstimate,
                isEmailDownload: initialState.isEmailDownload,
                isFirstEditEstimate: initialState.isFirstEditEstimate,
                pagination: {
                    activePage: state.pagination.activePage,
                    totalPages: getTotalPagesForUpdateSearchResult(action),
                },
                totalRecords: action.searchResults.totalRecords,
                recordsOnThePage: action.searchResults.recordsOnThePage,
                answerUpdated: initialState.answerUpdated,
                returnToEstimate: initialState.returnToEstimate,
                activeRiskId: initialState.activeRiskId,
                postcodeErrorMsg: initialState.postcodeErrorMsg,
                repriceInProgress: initialState.repriceInProgress,
            };

        case 'SEARCH_BY':
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    searchBy: action.searchBy,
                },
                pagination: {
                    activePage: 1,
                    totalPages: state.pagination.totalPages,
                },
            };
        case 'PER_PAGE_RECORDS':
            return {
                ...state,
                pageSize: action.pageSize,
                pagination: {
                    activePage: 1,
                    totalPages: state.pagination.totalPages,
                },
            };
        case 'SEARCH_TEXT':
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    searchText: action.searchText,
                },
                pagination: {
                    activePage: 1,
                    totalPages: state.pagination.totalPages,
                },
            };
        case 'GROUP_MNG_SEARCH_BY':
            return {
                ...state,
                groupSearchParams: {
                    ...state.groupSearchParams,
                    searchBy: action.searchBy,
                },
                grpPagination: {
                    activePage: 1,
                    totalPages: state.grpPagination.totalPages,
                },
            };
        case 'GROUP_MNG_SEARCH_TEXT':
            return {
                ...state,
                groupSearchParams: {
                    ...state.groupSearchParams,
                    searchText: action.searchText,
                },
                grpPagination: {
                    activePage: 1,
                    totalPages: state.grpPagination.totalPages,
                },
            };
        case 'FROM_DATE':
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    fromDate: action.fromDate,
                },
                pagination: {
                    activePage: 1,
                    totalPages: state.pagination.totalPages,
                },
            };
        case 'TO_DATE':
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    toDate: action.toDate,
                },
                pagination: {
                    activePage: 1,
                    totalPages: state.pagination.totalPages,
                },
            };
        case 'SEARCH_DATE_OPTION':
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    searchDateOption: action.searchDateOption,
                },
                pagination: {
                    activePage: 1,
                    totalPages: state.pagination.totalPages,
                },
            };
        case 'CLEAR_FILTER':
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    searchDateOption: "createdDate",
                    searchBy: "address",
                    searchText: "",
                    fromDate: null,
                    toDate: null
                },
            };
        case 'ADD_GRP_ENABLED':
            return {
                ...state,
                isAddGroupEnabled: action.isAddGrp,
                isFilterEnabled: false,
                isEditUserGroup: action.isEditUserGroup,
                userGroupOption: action.userGroupOption
            };
        case 'SORT_BASED_COLUMN_SELECTION':
            return {
                ...state,
                groupSortParams: {
                    sortBy: action.sortBy,
                    sortOrder: action.sortOrder,
                },
            };
        case 'USER_SORT_BASED_COLUMN_SELECTION':
            return {
                ...state,
                userSortParams: {
                    sortBy: action.sortBy,
                    sortOrder: action.sortOrder,
                },
            };
        case 'CLEAR_GROUP_FILTER':
            return {
                ...state,
                groupSearchParams: {
                    ...state.groupSearchParams,
                    searchBy: "companyName",
                    searchText: "",
                },
                grpPagination: {
                    activePage: 1,
                    totalPages: state.grpPagination.totalPages,
                },
                groupSortParams: {
                    sortBy: "role",
                    sortOrder: "descending",
                }
            };
        default:
            return state;
    }
}

function getFinalState1(action, state) {
    switch (action.type) {
        case 'OPEN_DISCLAIMER_DIALOG':
            return {
                ...state,
                footer: {
                    ...state.footer,
                    disclaimerOpen: action.open,
                },
            };
        case 'CHANGE_EMAIL':
            return {
                ...state,
                report: {
                    ...state.report,
                    sentReport: false,
                    error: null,
                },
                recipient: {
                    ...state.recipient,
                    data: {
                        ...state.recipient.data,
                        email: action.data,
                    },
                    error: false,
                },
            };
        case 'UPDATE_CHART_IMAGE':
            return {
                ...state,
                chartImage: action.payload
            };
        case 'CHANGE_EMAIL_SUBJECT':
            return {
                ...state,
                report: {
                    ...state.report,
                    sentReport: false,
                    error: null,
                },
                recipient: {
                    ...state.recipient,
                    data: {
                        ...state.recipient.data,
                        subject: action.data,
                    },
                    error: false,
                },
            };
        case 'CHANGE_EMAIL_MESSAGE':
            return {
                ...state,
                report: {
                    ...state.report,
                    sentReport: false,
                    error: null,
                },
                recipient: {
                    ...state.recipient,
                    data: {
                        ...state.recipient.data,
                        message: action.data,
                    },
                    error: false,
                },
            };
        case 'ATTEMPT_EMAIL_REPORT':
            return {
                ...state,
                report: {
                    ...state.report,
                    loading: true,
                    sentReport: false,
                },
            };
        case 'SUCCESS_EMAIL_REPORT':
            return {
                ...state,
                report: {
                    ...state.report,
                    loading: false,
                    sentReport: true,
                    error: null,
                },
                recipient: {
                    data: {
                        firstName: '',
                        lastName: '',
                        email: null,
                    },
                    error: false,
                },
                message: `Report sent to '${state.recipient.data.email}'`,
                automailMessage: null,
            };
        case 'SUCCESS_EMAIL_REPORT_CAPTURE':
            return {
                ...state,
                report: {
                    ...state.report,
                    loading: false,
                    sentReport: true,
                    error: null,
                },
                recipient: {
                    data: {
                        ...state.recipient.data,
                        firstName: '',
                        lastName: '',
                        email: null,
                    },
                    error: false,
                },
                message: action.autoMail ? null : `Report sent to '${state.recipient.data.email}'`,
                autoMailMessage: action.autoMail ? `We have emailed the report to '${state.userCapturedDetail.emailAddress}'` : null
            };
        case 'RECIPIENT_VALIDATION_ERROR':
            return {
                ...state,
                recipient: {
                    ...state.recipient,
                    error: true,
                    errorMsg: action.data,
                },
                report: {
                    ...state.report,
                    loading: false,
                    sentReport: false,
                    error: null,
                },
                result: {
                    ...state.result,
                }
            };
        case 'CHANGE_FIRST_NAME':
            return {
                ...state,
                recipient: {
                    ...state.recipient,
                    data: {
                        ...state.recipient.data,
                        firstName: action.data,
                    },
                    nameError: false,
                },
            };
        case 'CHANGE_LAST_NAME':
            return {
                ...state,
                recipient: {
                    ...state.recipient,
                    data: {
                        ...state.recipient.data,
                        lastName: action.data,
                    },
                    nameError: false,
                },
            };
        case 'ATTEMPT_PDF_REPORT':
            return {
                ...state,
                pdfReport: {
                    ...state.report,
                    loading: true,
                },
            };
        case 'SUCCESS_PDF_REPORT':
            return {
                ...state,
                pdfReport: {
                    ...state.pdfReport,
                    loading: false,
                },
                result: {
                    ...state.result,
                    error: null,
                },
                recipient: {
                    ...state.recipient,
                    data: {
                        ...state.recipient.data,
                    },
                },
            };
        case 'SERVER_UNAVAILABLE':
            return {
                ...state,
                maintenanceMode: true,
            };
        case 'ACCESS_UNAVAILABLE':
            return {
                ...state,
                accessDenied: true,
            };
        case 'SEARCH_AGAIN':
            return {
                ...state,
                recipient: {
                    data: {
                        firstName: '',
                        lastName: '',
                        email: null,
                    },
                    error: false,
                },
                currentDataPartition: '',
                displayRefreshWarning: false,
                defaultCalculation: false,
                calculateButtonClicked: false,
                showResult: !state.profile.display.attributesCheck,
                userCapturedDetail: {
                    emailAddress: initialState.emailAddress,
                    fullName: initialState.fullName,
                    error: initialState.error,
                },
            };
        case 'UPDATE_REFRESH_WARNING':
            return {
                ...state,
                displayRefreshWarning: action.data,
            };
        case 'UPDATE_CURRENT_PARTITION':
            return {
                ...state,
                currentDataPartition: action.data,
            };
        case 'LOADING_COMPLETED':
            return {
                ...state,
                questions: {
                    ...state.questions,
                    loading: false,
                },
                result: {
                    ...state.result,
                    loading: false,
                },
                report: {
                    ...state.report,
                    loading: false,
                },
                pdfReport: {
                    ...state.pdfReport,
                    loading: false,
                },
                isBuildingLoading: false,
            };
        case 'SET_SESSION_TRACKER':
            return {
                ...state,
                segment: {
                    sessionTrackId: action.data
                }
            };
        case 'OPEN_HELP_INFORMATION_DIALOG':
            return {
                ...state,
                helper: {
                    ...state.helper,
                    helpInformationOpen: action.help.open,
                    question: action.help.question,
                },
            };

        case 'OPEN_LET_US_KNOW_DIALOG':
            return {
                ...state,
                letusknow: {
                    ...state.letusknow,
                    dialogOpen: action.letusknow.open,
                    propertyId: action.letusknow.propertyId,
                    address: action.letusknow.address,
                },
            };

        case 'ATTEMPT_LET_US_KNOW_EMAIL':
            return {
                ...state,
                letusknow: {
                    ...state.letusknow,
                    loading: true
                },
            };

        case 'CLOSE_LET_US_KNOW_EMAIL':
            return {
                ...state,
                letusknow: {
                    ...state.letusknow,
                    loading: false
                },
            };

        case 'RETURN_TO_QUOTE':
            return {
                ...state,
                returnToQuote: action.returnData
            };
        case 'SHOW_RESULT_SECTION':
            return {
                ...state,
                showResult: action.showResult
            };
        case 'UPDATE_ANALYTICS_TIME_TRACK':
            return {
                ...state,
                addressChosenTime: action.time
            };
        case 'CALCULATE_BUTTON_CLICKED':
            return {
                ...state,
                calculateButtonClicked: action.calculateButtonClicked
            };
        case 'DEFAULT_CALCULATION':
            return {
                ...state,
                defaultCalculation: action.defaultCalculation
            };
        default:
            return state;
    }
}

function getFinalState(action, state, display, images, styles, text, {profileAccessControl, segmentTrackingCode}) {
    switch (action.type) {
        case 'ATTEMPT_FETCH_QUESTIONS':
            return {
                ...state,
                questions: {
                    ...state.questions,
                    loading: true,
                    error: null,
                },
                isBuildingLoading: true,
            };
        case 'RECEIVE_QUESTIONS':
            return {
                ...state,
                ...state.questions,
                questions: {
                    loading: false,
                    data: action.questions,
                    error: null,
                },
                result: {
                    manual: false,
                    loading: false,
                    data: action.calculation,
                    content: action.content,
                    error: null,
                    exceedLimitMessage: action.calculation.message,
                },
                isBuildingLoading: false,
            };
        case 'UPDATE_QUESTIONS':
            return {
                ...state,
                ...state.questions,
                questions: {
                    loading: false,
                    data: action.questions,
                    error: null,
                },
                estimateID: action.estimateId,
                riskId: action.risk.riskId,
                isEditEstimate: true,
                buildingGrp: action.buildingGrp,
                expandedIndex: getExpandedIndex(action),
                expanded: true,
                addressChosen: {
                    suggestion: action.address,
                    propertyId: action.propertyId,
                },
                riskDetail: {
                    policyNumber: action.risk.policyNumber,
                    insured: action.risk.insured,
                    underwriter: action.risk.underwriter,
                    lastValuation: action.risk.lastValuation,
                    assignedTo: action.risk.assignedTo,
                    address: action.risk.address,
                    propertyId: action.risk.propertyId,
                    postcode: action.risk.postcode,
                },
                estimatePrice: action.estimatePrice,
                cceImages: {
                    data: action.cceImages.images,
                },
                result: {
                    ...state.result,
                    loading: false,
                    data: action.calculationResult,
                },
            };
        case 'ADDRESS_CHOSEN':
            return {
                ...state,
                aerialImage: initialState.aerialImage,
                propertyImage: initialState.propertyImage,
                questions: initialState.questions,
                result: initialState.result,
                suggestions: initialState.suggestions,
                propertyId: action.propertyId,
                addressChosen: action.addressChosen,
                recipient: {
                    data: {
                        firstName: '',
                        lastName: '',
                        email: null,
                    },
                    error: false,
                },
            };
        case 'CCE_ADDRESS_CHOSEN':
            return {
                ...state,
                ...state.suggestions,
                propertyImage: initialState.propertyImage,
                aerialImage: initialState.aerialImage,
                propertyId: action.propertyId,
                addressChosen: action.cceAddressChosen,
                result: initialState.result,
                suggestions: state.suggestions,
                recipient: {
                    data: {
                        firstName: '',
                        lastName: '',
                        email: null,
                    },
                    error: false,
                },
                riskDetail: {
                    ...state.riskDetail,
                    address: action.cceAddressChosen.suggestion,
                    propertyId: action.cceAddressChosen.propertyId,
                },
                postcodeErrorMsg: initialState.postcodeErrorMsg,
                errorPropertyMsg: initialState.errorPropertyMsg,
            };
        case 'ANSWER_UPDATED':
            return {
                ...state,
                result: initialState.result,
                questions: {
                    loading: false,
                    data: action.questions,
                    error: null,
                },
                message: '',
                isBuildingLoading: false,
                answerUpdated: true,
            };
        case 'BUILDING_GRP_UPDATED_DEPENDENT':
            return {
                ...state,
                ...state.questions,

                buildingGrp: buildingGroupWhenTypeisUpdatedDependent(state, action)
            };
        case 'BUILDING_GRP_UPDATED':
            return {
                ...state,
                ...state.questions,
                result: initialState.result,
                questions: {
                    loading: false,
                    data: action.buildingDataUpdate.questions,
                    error: null,
                },
                buildingGrp: getBuildingGroupWhenActionTypeIsGrpUpdated(state, action),
                isBuildingLoading: false,
                answerUpdated: true,
            };
        case 'ATTEMPT_FETCH_RESULT':
            return {
                ...state,
                result: {
                    manual: true,
                    loading: true,
                    data: null,
                    error: null,
                },
            };
        case 'UPDATE_RESULT':
            return {
                ...state,
                result: {
                    manual: true,
                    loading: false,
                    data: action.result.calculation,
                    content: action.result.content,
                    error: null,
                    exceedLimitMessage: action.result.calculation.message,
                },
                calculateButtonClicked: true,
                estimateID: action.result.calculation.estimateId,
                estimatePrice: null,
                isIncompleteEstimate: false,
                suggestions: initialState.suggestions,
                answerUpdated: initialState.answerUpdated,
                returnToEstimate: initialState.returnToEstimate,
            };
        case 'VALIDATION_ERROR':
            return {
                ...state,
                errors: action.errors,
            };
        case 'SERVER_ERROR':
            return {
                ...state,
                result: {
                    ...state.result,
                    loading: false,
                    error: action.error,
                },
                questions: { ...state.questions, loading: false },
                report: { ...state.report, loading: false },
                recipient: { ...state.recipient, error: false },
                isBuildingLoading: false,
                searchResult: {
                    ...state.searchResult,
                    isLoading: false,
                }
            };
        case 'EMAIL_SEND_ERROR':
            return {
                ...state,
                result: {
                    ...state.result,
                    loading: false,
                },
                questions: { ...state.questions, loading: false },
                report: { ...state.report, loading: false, error: action.error, },
                recipient: { ...state.recipient, error: false },
                isBuildingLoading: false,
            };
        case 'PROFILE_UPDATED':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    display: {
                        ...state.profile.display,
                        ...display,
                    },
                    images: {
                        ...state.profile.images,
                        ...images,
                    },
                    styles: {
                        ...state.profile.styles,
                        ...styles,
                    },
                    text: {
                        ...state.profile.text,
                        ...text,
                    },
                    profileAccessControl: {
                        ...state.profile.profileAccessControl,
                        ...profileAccessControl,
                    },
                    segmentTrackingCode: action.profile.segmentTrackingCode,
                    ...segmentTrackingCode,
                    returnUrls: action.profile.returnUrls,
                    hashingPrivateKey: action.profile.hashingPrivateKey,
                    contactUsUrl: action.profile.contactUsUrl,
                    bypassTc: action.profile.bypassTc,
                    logoLeftUrl: action.profile.logoLeftUrl,
                    logoRightUrl: action.profile.logoRightUrl,
                    ctaButton1Url: action.profile.ctaButton1Url,
                },
                message: '',
                productId: action.productId,
                profileId: action.profileId,
                partnerId: action.partnerId,
                estimateID: initialState.estimateID,
                userCapturedDetail: {
                    emailAddress: initialState.emailAddress,
                    fullName: initialState.fullName,
                    error: initialState.error,
                },
            };
        case 'SET_DEBUG_MODE':
            return {
                ...state,
                debug: action.debug,
            };
        case 'SET_SHOW_NOTE':
            return {
                ...state,
                showNote: action.showNote,
            };
        case 'RECEIVE_IMAGES':
            return {
                ...state,
                aerialImage: action.aerialImage,
                propertyImage: action.propertyImage,
            };
        case 'RECEIVE_NO_IMAGE_FILE':
            return {
                ...state,
                noImageFile: action.noImageFile,
            };
        case 'RECEIVE_CCE_IMAGES':
            return {
                ...state,
                cceImages: {
                    ...state.cceImages,
                    data: action.images,
                }
            };
        case 'IMAGE_DESCRIPTION_CHANGED':
            return {
                ...state,
                cceImages: {
                    ...state.cceImages,
                    data: action.cceImages,
                },
            };
        case 'ATTEMPT_FETCH_SUGGESTIONS':
            return {
                ...state,
                suggestions: {
                    ...state.suggestions,
                    loading: true,
                    error: null,
                },
                riskDetail: {
                    ...state.riskDetail,
                    propertyId: null,
                },
            };
        case 'ATTEMPT_FETCH_SUGGESTIONS_RESET':
            return {
                ...state,
                suggestions: {
                    loading: true,
                    data: null,
                    error: null,
                },
                riskDetail: {
                    ...state.riskDetail,
                    propertyId: null,
                },
            };
        case 'SUCCEED_FETCH_SUGGESTIONS':
            return {
                ...state,
                suggestions: {
                    ...state.suggestions,
                    loading: false,
                    data: action.data,
                    error: null,
                },
            };
        case 'OPEN_DEBUG_DIALOG':
            return {
                ...state,
                debugReport: {
                    ...state.debugReport,
                    dialogOpen: true,
                },
            };
        case 'CLOSE_DEBUG_DIALOG':
            return {
                ...state,
                debugReport: {
                    ...state.debugReport,
                    dialogOpen: false,
                },
            };
        case 'UPDATE_DEBUG_TOKEN':
            return {
                ...state,
                debugReport: {
                    ...state.debugReport,
                    token: action.data,
                },
            };
        case 'INITIALIZE_SEGMENT_ANALYTICS':
            return {
                ...state,
                segmentAnalytics: action.segmentAnalytics,
            };
        case 'OPEN_TERMS_AND_CONDITIONS_DIALOG':
            return {
                ...state,
                footer: {
                    ...state.footer,
                    termsAndConditionsOpen: action.open,
                },
            };
        default:
            return state;
    }
}

function getAssignedToWhenActionTypeisNewRisk(state) {
    return state.loggedInUserDetail !== null && state.loggedInUserDetail.id !== null ?
        state.loggedInUserDetail.id : null;
}

function getClaudTokenMessage(state) {
    return state.loggedInUserDetail !== null && state.loggedInUserDetail.id !== null ? null : state.claudtoken.message;
}

function getTotalPagesForUpdateUserSearchResult(action) {
    return action.userSearchResult.cceUserDetailResponseList[0] === undefined ? null : action.userSearchResult.cceUserDetailResponseList[0].totalPages;
}

function getUserSearchResultForUpdateUserSearchResult(state, action) {
    return {
        ...state.userSearchResult,
        data: action.userSearchResult.cceUserDetailResponseList,
        error: null,
        totalElements: action.userSearchResult.cceUserDetailResponseList.size === 0 ||
            action.userSearchResult.cceUserDetailResponseList[0] === undefined ?
            0 : action.userSearchResult.cceUserDetailResponseList[0].totalElements,
        currentPageSize: action.userSearchResult.cceUserDetailResponseList.size === 0 ||
            action.userSearchResult.cceUserDetailResponseList[0] === undefined ?
            0 : action.userSearchResult.cceUserDetailResponseList[0].currentPageSize,
    };
}

function getTotalPagesForUpdateGroupSearchResult(action) {
    return action.userGroupSearchResult.cceUserGroupResponseList[0] === undefined ? null : action.userGroupSearchResult.cceUserGroupResponseList[0].totalPages;
}

function getUserGroupSearchResultForUpdateGroupSearchResult(state, action) {
    return {
        ...state.userGroupSearchResult,
        data: action.userGroupSearchResult.cceUserGroupResponseList,
        error: null,
        totalElements: action.userGroupSearchResult.cceUserGroupResponseList.size === 0 ||
            action.userGroupSearchResult.cceUserGroupResponseList[0] === undefined ?
            0 : action.userGroupSearchResult.cceUserGroupResponseList[0].totalElements,
        currentPageSize: action.userGroupSearchResult.cceUserGroupResponseList.size === 0 ||
            action.userGroupSearchResult.cceUserGroupResponseList[0] === undefined ?
            0 : action.userGroupSearchResult.cceUserGroupResponseList[0].currentPageSize,
    };
}

function getDeleteEstimateForActionTypeDeleteEstimate(action, state) {
    return {
        estimateDialog: action.data ? !state.deleteEstimate.estimateDialog : false,
        estimateId: (action.indexValue === null || action.indexValue === undefined) ? state.deleteEstimate.estimateId : action.indexValue,
        createdDate: state.deleteEstimate.createdDate
    };
}

function getDeleteEstimateForEstimateDetailDialog(action, state) {
    return {
        estimateDialog: action.data ? !state.deleteEstimate.riskDialog : false,
        estimateId: action.estimateDetail === null ? state.deleteEstimate.estimateId : action.estimateDetail.estimateId,
        createdDate: action.estimateDetail === null ? state.deleteEstimate.estimateDate : action.estimateDetail.estimateDate,
        estimatePrice: action.estimateDetail === null ? state.deleteEstimate.estimatePrice : action.estimateDetail.estimatePrice,
    };
}

function getDeleteRiskForDeleteRisk(action, state) {
    return {
        riskDialog: action.data ? !state.deleteRisk.riskDialog : false,
        riskId: (action.indexValue === null || action.indexValue === undefined) ? state.deleteRisk.riskId : action.indexValue,
        address: state.deleteRisk.address
    };
}

function getDeleteRiskForRiskDetailDialog(action, state) {
    return {
        riskDialog: action.data ? !state.deleteRisk.riskDialog : false,
        riskId: action.riskDetail === null ? action.riskIds : action.riskDetail.riskId,
        address: action.riskDetail === null ? null : action.riskDetail.address
    };
}

function getCCEImagesDataForEstimatePreview(action) {
    return action.data.cceEstimateLogDetailsList[0] === undefined ? null : action.data.cceEstimateLogDetailsList[0].cceImages.images;
}

function getTotalPagesForUpdateSearchResult(action) {
    return action.searchResults.riskDetailsResponseList[0] === undefined ? null : action.searchResults.riskDetailsResponseList[0].totalPages;
}

function getExpandedWhenActionTypeIsCollapsePAnelUpdated(action, state) {
    return action.expandedIndex === action.secondIndex || state.expanded === false ? !state.expanded : state.expanded;
}

function getBuildingGroupWhenActionTypeIsGrpUpdated(state, action) {
    return state.buildingGrp.map((buildingGrp, grpIndex) => {
        if (action.buildingDataUpdate.index !== grpIndex) {
            return buildingGrp;
        }
        else {
            return action.buildingDataUpdate.group;
        }
    });
}

function buildingGroupWhenTypeisUpdatedDependent(state, action) {
    return state.buildingGrp.map((buildingGrp, grpIndex) => {
        if (action.buildingData.index === grpIndex) {
            return action.buildingData.group;
        }
        else {
            return buildingGrp;
        }
    });
}

function getExpandedIndex(action) {
    return action.buildingGrp.length > 0 ? action.buildingGrp.length - 1 : 0;
}
