import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { editAnswer, updateErrors, validateUserAnswer, cceEditAnswer } from '../actions/questionsActionCreator';

import InputField from '../components/InputField';

const mapStateToProps = (state, otherProps) => {
    return {
        ...state,
        ...otherProps,
        postcode: state.postcode,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        editAnswer,
        updateErrors,
        validateUserAnswer,
        cceEditAnswer
    }, dispatch);
};

export const mergeProps = (stateProps, dispatchProps, otherProps) => {

    const errors = stateProps.errors;
    let errorMsg = null;
    if (errors.length > 0) {
        for (let i = 0; i < errors.length; i++) {
            if (stateProps.question.id === errors[i].questionId) {
                errorMsg = errors[i].errorMsg;
                break;
            }
        }
    }
    return {
        postcode: stateProps.postcode,
        question: stateProps.question,
        styles: stateProps.styles,
        productId: stateProps.productId,
        profileId: stateProps.profileId,
        index: otherProps.index,
        sectionName: otherProps.sectionName,
        editAnswer: (answer, shouldTriggerReload = true) => {
            dispatchProps.editAnswer(
                stateProps.productId,
                stateProps.profileId,
                stateProps.questions,
                answer,
                stateProps.addressChosen,
                shouldTriggerReload,
                stateProps.partnerId,
            );
        },
        cceEditAnswer: (answer, shouldTriggerReload = true) => {
            dispatchProps.cceEditAnswer(
                otherProps.sectionName,
                otherProps.index,
                stateProps.productId,
                stateProps.profileId,
                stateProps.buildingGrp[otherProps.index],
                answer,
                stateProps.addressChosen,
                shouldTriggerReload,
                stateProps.partnerId,
            );
        },
        updateErrors: (isError, errorMsg1, questionId) => {
            dispatchProps.updateErrors(isError, errorMsg1, questionId);
        },
        validateUserAnswer: (productId, attributeName, value, questionId) => {
            dispatchProps.validateUserAnswer(productId, attributeName, value, questionId);
        },
        errorMsg: errorMsg,
    };
};

const InputFieldContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(InputField);
export default InputFieldContainer;