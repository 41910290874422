import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, sortBy } from 'lodash';
import { Card, CardText } from 'material-ui/Card';
import { TextField } from 'material-ui';
import { inputStyle } from '../style/styles';
import SectionHeader from './SectionHeader';
import SearchSuggestions from './SearchSuggestions';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UrlConstants from '../constants/UrlConstants';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@material-ui/core';
import StrataAddressSearch from './StrataAddressSearch';

class RiskDetail extends React.Component { 
    componentWillMount() {
        const {   updateCurrentTab, onLoad } = this.props;
        updateCurrentTab(UrlConstants.RISK_DETAILS);
        onLoad();
    }

render() {
    const { searchText, value, results, suggestions, autofillAddress, onSearchTermChanged, onChange,handleProperty,onNewRequest, styles, productId, profileId, fontFamilyName,
        onPolicyNumberPicked, onAssignedToChange, onUnderwriterChange, onInsuredChange, onLastValuationChange, isRiskIdTriggered, riskDetail,  riskId,
        onRiskIdChange, isEditRisk, userList, loggedInUserDetail, handleChange, isEditEstimate, isEmailDownload, addressChosen,errorPropertyMsg, onPropertyIdChange,
        onPostcodeChange, isPropertyIdTriggered, result, postcodeErrorMsg, showPostcodeMessage, isPostcodeLocked,
        onAddressPicked, onStrataPlanNumberChange
    } = this.props;
    const message = UrlConstants.RISK_DETAIL_MESSAGE;
    const propertyIdMessage = UrlConstants.PROPERTY_ID_MESSAGE;

    const { newAddressValue, newSearchText } = getNewAddressAndNewSearchText(isEditRisk, riskDetail, value, searchText);
    const userListSort = sortBy ( userList.data , (assignedTo) => {
        return assignedTo.fullName.toLowerCase();
    });

    const handleNext = (event, newValue) => {

        handleChange(UrlConstants.BUILDING_DETAILS);

    };

    const handlePostcodeChange = getHandlePostcodeChangeValue({isEditRisk, result, addressChosen}, newAddressValue, onAddressPicked, onPostcodeChange, productId, showPostcodeMessage)

    return (
        <div className="RiskDetail">
            <Card className="risk-card" containerStyle={{ paddingBottom: 0, fontFamily: styles.globalFontFamily }}>
                <SectionHeader name="Risk Details" color={styles.sectionTileColour} styles={styles} />
                <div className="risk-detail">
                    <CardText className="risk-detail-form">
                        <label id="property-address" className="risk-detail-label">Property Address:<p className="risk-mandat">*</p></label>
                        <div className="risk-detail-field">
                                {productId === UrlConstants.PRODUCT_ID_11 ? (

                                    <StrataAddressSearch
                                        newValue={newAddressValue}
                                        onChange={onChange}
                                        name="addressInput"
                                        fontFamilyName={fontFamilyName}
                                    />
                                ) : (<SearchSuggestions
                                    searchText={newSearchText}
                                    newValue={newAddressValue}
                                    results={results}
                                    suggestions={suggestions}
                                    onNewRequest={onNewRequest}
                                    onChange={onChange}
                                    onBlur={handleProperty}
                                    onSearchTermChanged={onSearchTermChanged}
                                    styles={styles}
                                    productId={productId}
                                    showSearchIcon={!autofillAddress}
                                    name="addressInput"
                                    profileId={profileId}
                                    fontFamilyName={fontFamilyName}
                                    isEditRisk={isEditRisk}
                                    resultData={result}
                                />)}
                            </div>
                    </CardText>
                    {newFunction1(errorPropertyMsg)}
                    <CardText className="risk-detail-form">
                        <label id="postcode" className="risk-detail-label">Postcode:<p className="risk-mandat">*</p></label>
                        <div className="risk-detail-field">
                            <TextField
                                className="risk-field"
                                id="postcode"
                                type="number"
                                disabled={false}
                                fullWidth={true}
                                value={getTextFieldValue(riskDetail)}
                                onChange={textFieldOnChange(handlePostcodeChange)}
                                floatingLabelFixed={true}
                                inputStyle={{ ...inputStyle, fontFamily: fontFamilyName }}
                            />
                        </div>
                    </CardText>
                    { newFunction2(postcodeErrorMsg)}
                    { newFunction3(postcodeErrorMsg, isPostcodeLocked)}
                    <CardText className="risk-detail-form" style={{ display: "none" }} >
                        <label id="propertyID" className="risk-detail-label">Corelogic Property ID: </label>
                        <div className="risk-detail-field">
                            <TextField
                                className="risk-field"
                                id="propertyID"
                                type="text"
                                fullWidth={true}
                                value={valueWhenPropertyIdIsNotNull(errorPropertyMsg, riskDetail)}
                                onChange={() => { onPropertyIdChange(); }}
                                floatingLabelFixed={true}
                                inputStyle={{ ...inputStyle, fontFamily: fontFamilyName }}
                            />
                        </div>
                    </CardText>
                    {newFunction4(isPropertyIdTriggered, propertyIdMessage)}
                    {newFunction5(riskId, onRiskIdChange, fontFamilyName)}
                    {newFunction6(isRiskIdTriggered, message)}
                    {newFunction7(riskId, riskDetail, onPolicyNumberPicked, fontFamilyName)}
                    <CardText className="risk-detail-form" style={{ marginTop: '-5px' }}>
                        <label id="insured" className="risk-detail-label">Insured: </label>
                        <div className="risk-detail-field">
                            {newFunction8(riskDetail, fontFamilyName, onInsuredChange)}
                        </div>
                    </CardText>
                    <CardText className="risk-detail-form" style={{ marginTop: '-5px' }}>
                        <label id="underwriter" className="risk-detail-label">Underwriter:</label>
                        <div className="risk-detail-field">
                            {newFunction9(riskDetail, fontFamilyName, onUnderwriterChange)}
                        </div>
                    </CardText>
                    <CardText className="risk-detail-form" style={{ marginTop: '-5px' }}>
                        <label id="assigned-to" className="risk-detail-label">Assigned To: </label>
                        <div className="risk-detail-field">
                            <FormControl style={{ width: '100%' }}>
                                <Select id="assigned" className="select-box"
                                    fullWidth={true}
                                    style={{ fontFamily: fontFamilyName }}
                                    value={riskDetail.assignedTo !== null ? riskDetail.assignedTo :
                                        this.newMethod1(loggedInUserDetail)}
                                    onChange={(event) => {
                                        onAssignedToChange({ assignedTo: event.target.value });
                                    }}
                                    tabIndex={1} >
                                    {userListSort.map((option) => (option.label === "[Blank]" || option.label === "[blank]") ? "" :
                                        <MenuItem key={option.id}
                                            value={option.id}>
                                            {option.fullName}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                    </CardText>
                    <CardText className="risk-detail-form" style={{ marginTop: '-5px' }}>
                        <label id="last-valuation" className="risk-detail-label">Last Valuation Date: </label>
                        <div className="risk-detail-field-datePicker">
                            <DatePicker className="date"
                                style={{ fontFamily: fontFamilyName }}
                                dateFormat="yyyy/MM/dd"
                                selected={riskDetail.lastValuation !== null ? new Date(riskDetail.lastValuation) : null}
                                 placeholderText="Click to select valuation date"
                                onChange={date => onLastValuationChange(date)}
                                showMonthDropdown
                                maxDate={new Date()}
                                showYearDropdown
                                useShortMonthInDropdown
                                yearDropdownItemNumber={10}
                                scrollableYearDropdown />
                        </div>
                    </CardText>
                    {(productId === "11") &&
                    <CardText className="risk-detail-form" style={{ marginTop: '-5px' }}>
                        <label id="strata-plan-number" className="risk-detail-label">Strata Plan Number:</label>
                        <div className="risk-detail-field">
                            {renderStrataPlanNumber(riskDetail, fontFamilyName, onStrataPlanNumberChange)}
                        </div>
                    </CardText>
                    }
                </div>
                
            </Card>
            <div>{(isEmpty(addressChosen)&& !isEditRisk ) &&<span style={{ fontSize: '0.9rem', color: 'red',margin: '-0.3rem 0rem 0rem 10.7rem' }}>Please fill in all mandatory fields marked with an asterisk (*)
                                with valid values</span>}</div>
            <div style={{ alignSelf: 'flex-end'}}><Button className="action_risk" variant="outlined" id="tab-1"
                        rounded="true"
                        size="large"
                        tabIndex={0}
                        disabled={newFunction10(addressChosen, isEditRisk, isEmailDownload, errorPropertyMsg, postcodeErrorMsg, isEditEstimate)}
                        handleNext={handleNext}
                        disableTouchRipple={true}
                        disableFocusRipple={false}
                        onClick={handleNext}
                    > Building Details &rarr;
                        </Button>
            </div>
        </div >
    );
}

    newMethod1(loggedInUserDetail) {
        return loggedInUserDetail != null ? loggedInUserDetail.id : "select";
    }
}

RiskDetail.propTypes = {
    searchText: PropTypes.string,
    results: PropTypes.array.isRequired,
    value: PropTypes.string,
    suggestions: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSearchTermChanged: PropTypes.func,
    onNewRequest: PropTypes.func.isRequired,
    productId: PropTypes.string.isRequired,
    onPolicyNumberPicked: PropTypes.func.isRequired,
    onUnderwriterChange: PropTypes.func.isRequired,
    onInsuredChange: PropTypes.func.isRequired,
    onAssignedToChange: PropTypes.func.isRequired,
    onLastValuationChange: PropTypes.func.isRequired,
    onStrataPlanNumberChange: PropTypes.func.isRequired,
    handleProperty: PropTypes.func.isRequired,
};

export default RiskDetail;

function newFunction10(addressChosen, isEditRisk, isEmailDownload, errorPropertyMsg, postcodeErrorMsg, isEditEstimate) {
    return (isEmpty(addressChosen) || isEditRisk || isEmailDownload || errorPropertyMsg !== null || postcodeErrorMsg !== null) && !isEditEstimate ? true : false;
}

function newFunction9(riskDetail, fontFamilyName, onUnderwriterChange) {
    return <TextField
        className="risk-field"
        fullWidth={true}
        type="text"
        id="Underwriter"
        value={riskDetail.underwriter !== null ? riskDetail.underwriter : ""}
        floatingLabelFixed={true}
        inputStyle={{ ...inputStyle, fontFamily: fontFamilyName }}
        onChange={(event, newValue) => {
            onUnderwriterChange(isEmpty(newValue) ? null : newValue);
        } } />;
}

function renderStrataPlanNumber(riskDetail, fontFamilyName, onStrataPlanNumberChange) {
    return <TextField
        className="risk-field"
        fullWidth={true}
        type="text"
        id="strata-plan-number"
        value={riskDetail.strataPlanNumber !== null ? riskDetail.strataPlanNumber : ""}
        floatingLabelFixed={true}
        inputStyle={{ ...inputStyle, fontFamily: fontFamilyName }}
        onChange={(event, newValue) => {
            onStrataPlanNumberChange(isEmpty(newValue) ? null : newValue);
        } } />;
}

function newFunction8(riskDetail, fontFamilyName, onInsuredChange) {
    return <TextField
        className="risk-field"
        fullWidth={true}
        id="insured"
        type="text"
        value={riskDetail.insured !== null ? riskDetail.insured : ""}
        floatingLabelFixed={true}
        inputStyle={{ ...inputStyle, fontFamily: fontFamilyName }}
        onChange={(event, newValue) => {
            onInsuredChange(isEmpty(newValue) ? null : newValue);
        } } />;
}

function newFunction7(riskId, riskDetail, onPolicyNumberPicked, fontFamilyName) {
    return <CardText className="risk-detail-form" style={{ marginTop: riskId ? '-5px' : '0px' }}>
        <label id="policy" className="risk-detail-label">Policy Number: </label>
        <div className="risk-detail-field">
            <TextField
                className="risk-field"
                id="policy"
                type="text"
                fullWidth={true}
                value={riskDetail.policyNumber !== null ? riskDetail.policyNumber : ""}
                onChange={(event, newValue) => {
                    const policyValue = newValue;
                    onPolicyNumberPicked(isEmpty(policyValue) ? null : policyValue);
                } }
                floatingLabelFixed={true}
                inputStyle={{ ...inputStyle, fontFamily: fontFamilyName }} />
        </div>
    </CardText>;
}

function newFunction6(isRiskIdTriggered, message) {
    return isRiskIdTriggered && <div className="riskId-message" style={{ paddingRight: '2.5rem', fontSize: '0.7rem', margin: '-1.6rem 0rem 0.62rem 10.7rem' }}>{message}</div>;
}

function newFunction5(riskId, onRiskIdChange, fontFamilyName) {
    return riskId && <CardText className="risk-detail-form" style={{ display: "none" }}>
        <label id="riskId" className="risk-detail-label">RiskId: </label>
        <div className="risk-detail-field">
            <TextField
                className="risk-field"
                id="riskId"
                type="text"
                fullWidth={true}
                value={riskId}
                onChange={() => { onRiskIdChange(); } }
                floatingLabelFixed={true}
                inputStyle={{ ...inputStyle, fontFamily: fontFamilyName }} />
        </div>
    </CardText>;
}

function newFunction4(isPropertyIdTriggered, propertyIdMessage) {
    return isPropertyIdTriggered && <div className="property-id-message" style={{ paddingRight: '2.5rem', fontSize: '0.7rem', margin: '-1.6rem 0rem 0.62rem 10.7rem' }}>{propertyIdMessage}</div>;
}

function valueWhenPropertyIdIsNotNull(errorPropertyMsg, riskDetail) {
    return errorPropertyMsg === null && riskDetail.propertyId !== null ? riskDetail.propertyId : "";
}

function newFunction3(postcodeErrorMsg, isPostcodeLocked) {
    return postcodeErrorMsg === null && isPostcodeLocked && <div className="postcode-message" style={{ paddingRight: '2.5rem', fontSize: '0.7rem', margin: '-1.6rem 0rem 0.62rem 10.7rem' }}>
        {UrlConstants.POSTCODE_MESSAGE}</div>;
}

function newFunction2(postcodeErrorMsg) {
    return postcodeErrorMsg !== null && <div className="risk-validation" style={{ fontSize: '0.9rem', color: 'red', margin: '-0.3rem 0rem 0rem 10.7rem' }}>
        {postcodeErrorMsg}</div>;
}

function textFieldOnChange(handlePostcodeChange) {
    return (event, newValue) => {
        const postcodeValue = newValue;
        handlePostcodeChange(isEmpty(postcodeValue) ? null : postcodeValue);
    };
}

function getTextFieldValue(riskDetail) {
    return riskDetail.postcode !== null ? riskDetail.postcode : "";
}

function newFunction1(errorPropertyMsg) {
    return errorPropertyMsg !== null && <div className="risk-validation" style={{ fontSize: '0.9rem', color: 'red', margin: '-0.3rem 0rem 0rem 10.7rem' }}>{errorPropertyMsg}</div>;
}

function getHandlePostcodeChangeValue({isEditRisk, result, addressChosen}, newAddressValue, onAddressPicked, onPostcodeChange, productId, showPostcodeMessage) {
    return (postcodeValue) => {
        if ((postcodeValue === null || (postcodeValue != null && postcodeValue.trim().length <= 5)) && !isEditRisk && (result.data === null || result.data.estimateId === null)) {
            if (addressChosen !== null && addressChosen !== undefined && addressChosen.suggestion !== newAddressValue) {
                onAddressPicked({
                    propertyId: null,
                    suggestion: newAddressValue,
                });
            }
            onPostcodeChange(productId, newAddressValue, postcodeValue);
        } else if (isEditRisk || (result.data !== null && result.data.estimateId !== null)) {
            showPostcodeMessage();
        }
    };
}

function getNewAddressAndNewSearchText(isEditRisk, riskDetail, value, searchText) {
    const newAddressValue = isEditRisk && riskDetail.address !== null ? riskDetail.address : value;
    const newSearchText = searchText === "" && isEditRisk && riskDetail.address !== null ? riskDetail.address : searchText;
    return { newAddressValue, newSearchText };
}
