import apiBaseUrl from '../apiBaseUrl';

const saveRiskDetailApi = (productId, profileId, riskDetail, addressChosen, propertyId, postcode) => {
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/saveRiskDetail`;
    const headers = {
        'Content-Type': 'application/json',
    };
    return fetch(url,
        {
            method: 'POST',
            headers,
            body: JSON.stringify({
                policyNo: riskDetail.policyNumber,
                insured: riskDetail.insured,
                underwriter: riskDetail.underwriter,
                lastValuation: riskDetail.lastValuation,
                assignedTo: riskDetail.assignedTo,
                address: addressChosen,
                propertyId: propertyId,
                postcode: postcode,
                strataPlanNumber: riskDetail.strataPlanNumber,
            }),
        }
    );


};

export default saveRiskDetailApi;