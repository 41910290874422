import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import CCEHomePage from '../components/CCEHomePage';
import {
    cceAddressPicked,
    fetchProfile,
    setDebug,
    validateProfileAccessControls,
    updateImageDescription,
    fetchNoImageFileUrl,
    onMessageHide, updatePropertyError
} from '../actions/actionCreator';
import { updateRiskAndReturnNewEstimate, cancelEdit, onTabChange, onTabUserChange, onTabImportRisksChange, onTabReportsChange, closeReturnToEstimateDialog, cancelAnswerUpdate,
    updatePostcodeQuestions, showReturnToEstimate } from '../actions/questionsActionCreator';
import { initializeAnalytics, sessionTracker, trackSegmentPageView } from '../actions/segmentActionsCreator';
import AnalyticsPage from '../util/AnalyticsPage';
import { saveInCompleteEstimate } from '../actions/calculationActionCreator';
import { getUserList, updateAssignedtoForNewRisk, setPostcode, resetAddEditEstimate, resetRiskDetails } from '../actions/searchActionCreator';
import { logout, resetTokenDetails, restoreURL, getUserDetailByUserGuid, fetchApigeeToken } from '../actions/loginActionCreator';

const mapStateToProps = (state, defaultProps) => {
    const queryParams = queryString.parse(defaultProps.location.search);
    return {
        isEstimateDirect: state.isEstimateDirect,
        profileId: defaultProps.match.params.profileId,
        productId: defaultProps.match.params.productId,
        debug: queryParams.debug === 'true',
        propertyId: queryParams.propertyId,
        token: queryParams.token,
        partner: queryParams.partner,
        suggestions: state.suggestions,
        config: state.config,
        images: state.profile.images,
        styles: state.profile.styles,
        text: state.profile.text,
        mode: state.profile.profileAccessControl.accessMode,
        key: `${defaultProps.match.params.profileId}-${defaultProps.match.params.productId}`,
        segmentTrackingCode: state.profile.segmentTrackingCode,
        acceptPropertyId: state.profile.display.acceptPropertyId,
        requestUrl: window.location.href,
        bypassTc: state.profile.bypassTc,
        logoLeftUrl: state.profile.logoLeftUrl,
        logoRightUrl: state.profile.logoRightUrl,
        riskDetail: state.riskDetail,
        addressChosen: state.addressChosen,
        questions: state.questions,
        result: state.result,
        widthChange: state.widthChange,
        open: state.open,
        isRiskIdTriggered: state.isRiskIdTriggered,
        isEditRisk: state.isEditRisk,
        riskId: state.riskId,
        isEditEstimate: state.isEditEstimate,
        isIncompleteEstimate: state.isIncompleteEstimate,
        buildingGrp: state.buildingGrp,
        estimateID: state.estimateID,
        isEmailDownload: state.isEmailDownload,
        isAddGroupEnabled: state.isAddGroupEnabled,
        isUserGroupOptionSystem: state.isUserGroupOptionSystem,
        isUsageReport: state.isUsageReport,
        isFilterEnabled: state.isFilterEnabled,
        isEditUserGroup: state.isEditUserGroup,
        isFilterUserEnabled: state.isFilterUserEnabled,
        isAddUserEnabled: state.isAddUserEnabled,
        isEditUserEnabled: state.isEditUserEnabled,
        userId: state.userId,
        claudtokenToken: state.claudtoken.access_token,
        isSessionLoaded: !!state.claudtoken.access_token,
        homePath: state.homePath,
        loggedInUserDetail : state.loggedInUserDetail,
        tokenContents : state.tokenContents,
        logoutFlag : queryParams.logout,
        location: defaultProps.location.search,
        errors : state.errors,
        handleProperty: state.handleProperty,
        errorPropertyMsg: state.errorPropertyMsg,
        depdencyErrors: state.depdencyErrors,
        statePostCode: state.postcode,
        postcodeErrorMsg: state.postcodeErrorMsg,
        answerUpdated: state.answerUpdated,
        estimatePrice: state.estimatePrice,
        returnToEstimateDialog: state.returnToEstimateDialog,
    };
};

export const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        cceAddressPicked,
        fetchProfile,
        setDebug,
        trackSegmentPageView,
        sessionTracker,
        validateProfileAccessControls,
        initializeAnalytics,
        updateImageDescription,
        fetchNoImageFileUrl,
        onMessageHide,
        updateRiskDetail: updateRiskAndReturnNewEstimate,
        cancelEdit: cancelEdit,
        saveInCompleteEstimate: saveInCompleteEstimate,
        onTabChange: onTabChange,
        onTabUserChange: onTabUserChange,
        onTabImportRisksChange: onTabImportRisksChange,
        onTabReportsChange: onTabReportsChange,
        getUserList: getUserList,
        logout: logout,
        resetTokenDetails: resetTokenDetails,
        restoreURL: restoreURL,
        getUserDetailByUserGuid : getUserDetailByUserGuid,
        updateAssignedtoForNewRisk: updateAssignedtoForNewRisk,
        fetchApigeeToken: fetchApigeeToken,
        onChangePostcode: setPostcode,
        resetAddEditEstimate: resetAddEditEstimate,
        resetRiskDetails: resetRiskDetails,
        updatePropertyError: updatePropertyError,
        closeReturnToEstimateDialog: closeReturnToEstimateDialog,
        cancelAnswerUpdate: cancelAnswerUpdate,
        updatePostcodeQuestions: updatePostcodeQuestions,
        showReturnToEstimate: showReturnToEstimate,
    }, dispatch);
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        onAddressPicked: dispatchProps.cceAddressPicked,
        fetchAddressApi: dispatchProps.fetchAddressApi,
        messageHide: dispatchProps.onMessageHide,
        onTabChange: dispatchProps.onTabChange,
        onTabUserChange: dispatchProps.onTabUserChange,
        onTabImportRisksChange: dispatchProps.onTabImportRisksChange,
        onTabReportsChange: dispatchProps.onTabReportsChange,
        validateProfile: () => {
            dispatchProps.fetchProfile(stateProps.productId, stateProps.profileId, stateProps.token, stateProps.partner);
        },
        setDebug: () => {
            dispatchProps.setDebug(stateProps.debug);
        },
        setValidateProfile: () => {
            dispatchProps.validateProfileAccessControls(stateProps.mode);
        },
        onLoad: (segmentTrackingCode) => {
            dispatchProps.initializeAnalytics(segmentTrackingCode);
            const pageDetails = new AnalyticsPage(stateProps.productId, stateProps.profileId, 'Sum Sure', ownProps.location.pathname, stateProps.sessionTrackId, "HomePage", stateProps.partner);
            dispatchProps.trackSegmentPageView('Home Page', pageDetails);
        },
        startTrackSession: () => {
            dispatchProps.sessionTracker((new Date()).getTime());
        },
        updateImagesDescription: dispatchProps.updateImageDescription,
        getNoImageFile: dispatchProps.fetchNoImageFileUrl,
        updateRiskDetail: () => {
            dispatchProps.updateRiskDetail(stateProps.productId, stateProps.profileId, stateProps.riskId, stateProps.riskDetail);
        },
        cancelEdit: () => {
            dispatchProps.cancelEdit(stateProps.productId, stateProps.profileId);
        },
        saveInCompleteEstimate: () => {
            let obj1 = {
                productId : stateProps.productId, 
                profileId : stateProps.profileId, 
                propertyId : stateProps.addressChosen.propertyId
            };
            dispatchProps.saveInCompleteEstimate(obj1, stateProps.addressChosen.suggestion, stateProps.questions, stateProps.riskId, stateProps.buildingGrp, stateProps.estimateID)
        },
        getUserList: dispatchProps.getUserList,
        onLogout: dispatchProps.logout,
        onResetTokenDetails: () => {
            dispatchProps.resetTokenDetails();
        },
        restoreURL:  dispatchProps.restoreURL,
        getUserDetailByUserGuid: dispatchProps.getUserDetailByUserGuid,
        updateAssignedtoForNewRisk: dispatchProps.updateAssignedtoForNewRisk,
        fetchApigeeToken: dispatchProps.fetchApigeeToken,
        onChangePostcode: dispatchProps.onChangePostcode,
        handleProperty: dispatchProps.handleProperty,
        resetAddEditEstimate: dispatchProps.resetAddEditEstimate,
        resetRiskDetails: dispatchProps.resetRiskDetails,
        updatePropertyError: dispatchProps.updatePropertyError,
        closeReturnToEstimateDialog: dispatchProps.closeReturnToEstimateDialog,
        cancelAnswerUpdate: dispatchProps.cancelAnswerUpdate,
        updatePostcodeQuestions: dispatchProps.updatePostcodeQuestions,
        showReturnToEstimate: dispatchProps.showReturnToEstimate,
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CCEHomePage);