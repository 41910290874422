import { filter, toString, findIndex } from 'lodash';
import calculateApi from '../api/calculateApi';
import billingTokenApi from '../api/billingTokenApi';
import hashApi from '../api/hashApi';
import DataRefreshError from './DataRefreshError';
import ServerError from './ServerError';
import history from '../util/history';
import debugReportApi from '../api/debugReportApi';
import { trackSegmentEvent, clearSegmentEventMap } from './segmentActionsCreator';
import { SegmentTrackingEventTypes } from '../constants/SegmentTrackingEventTypes';
import AnalyticsEstimate from '../util/AnalyticsEstimate';
import AnalyticsError from '../util/AnalyticsError';
import { validateAnswerApi } from "../api/validateAnswerApi";
import AnalyticsConstant from '../constants/AnalyticsConstant';
import AnalyticsCustomScript from "../util/AnalyticsCustomScript";
import UrlConstants from '../constants/UrlConstants';
import DomainConstants from "../constants/DomainConstants";
import WestpacGTagScript from "../util/WestpacGTagScript";
import WestpacGTagDLEnd from "../util/WestpacGTagDLEnd";
import WestpacGenericGTagDLEnd from "../util/WestpacGenericGTagDLEnd";
import saveInCompleteEstimateApi from '../api/saveInCompleteEstimateApi';
import copyImageApi from '../api/copyImageApi';
import { emailReport} from '../actions/actionCreator';

const attemptFetchResult = () => {
    return { type: 'ATTEMPT_FETCH_RESULT' };
};

const toAnswer = (question) => {
    return { question: question.id, answer: question.answer, visible: question.visible, answerSource: question.answerSource };
};

const toBuildingList = (buildingGrp) => {
    const buildingList = [];
    if (buildingGrp !== undefined) {
        buildingGrp.forEach(grp => {
            const buildingAnswers = [];
            grp.forEach(group => {
                group.questions.forEach(question => buildingAnswers.push(toAnswer(question)));
            });
            buildingList.push(buildingAnswers);
        });
    }
    return buildingList;
};

const toDebugAnswer = (question) => {
    let answerLabel;
    if (question.options && question.options.length > 0) {
        let selectedAnswer = question.options.filter(option => toString(option.id) === question.answer)[0];
        if (selectedAnswer) {
            answerLabel = selectedAnswer.label;
        }
    }
    return { question: question.id, answer: question.answer, answerLabel: answerLabel, visible: question.visible };
};

const handleJSONResponse = (response) => {
    if (response.ok) {
        return response.json();
    } else {
        return response.text().then((error) => {
            throw new ServerError(error, response.status);
        });
    }
};

const handleTextResponse = (response) => {
    if (response.ok) {
        return response.text();
    } else {
        return response.text().then((error) => {
            throw new ServerError(error, response.status);
        });
    }
};

export const serverError = (error) => {
    if (error.status === 503) {
        history.push('/unavailable');
        return { type: 'SERVER_UNAVAILABLE', error: error.message };
    } else if (error.status === 404) {
        history.push('/not_found');
        return { type: 'SERVER_ERROR', error: error.message };
    } else {
        return { type: 'SERVER_ERROR', error: error.message };
    }
};

export const updateResult = (result) => {
    return { type: 'UPDATE_RESULT', result };
};

export const isCustomerProfile = async (profile) => {
    return profile !== null && profile.display.includeEmailAddress && !profile.display.restrictDownloadReport;
};

export const emailReportIfCustomProfile = (custProfile) => {
    return async (dispatch) => {
             if (custProfile) {
                return dispatch(emailReport(true));
            }
        }
};

export const validate = async (productId, questions) => {
    const errors = [];
    const visibles = filter(questions, (question) => question.visible);
    for (const question of visibles) {
        if (question.mandatory && question.answer === '') {
            errors.push({
                questionId: question.id,
                errorMsg: 'Please provide an answer for this required field'
            });
            continue;
        }
        if (question.answer) {
            if (question.range) {
                if (question.answer < question.range.from || question.answer > question.range.to) {
                    errors.push({
                        questionId: question.id,
                        errorMsg: `Your answer should be in the range {from: ${question.range.from}, to: ${question.range.to}}`
                    });
                    continue;
                }
            }
        }
        await getResponseWhenQuestionTextEqualsPostcode(question, productId, errors);
    }
    return errors;
};

export const closeDebugDialog = () => {
    return { type: 'CLOSE_DEBUG_DIALOG' };
};

export const performCalculation = ({productId, profileId, propertyId, address, questions}, contentInsurance, partnerId, estimateID, riskId, buildingGrp, estimatePrice) => {
    return async (dispatch, getState) => {
        const { defaultCalculation, calculateButtonClicked, isEditEstimate,
            isFirstEditEstimate, profile, userCapturedDetail} = getState();
        const errors = await validate(productId, questions.data);
        const estimateEdited = isEditEstimate && isFirstEditEstimate;
        const custProfile = await isCustomerProfile(profile);
        if (errors.length > 0) {
            dispatch({ type: 'VALIDATION_ERROR', errors: errors });
        } else {
            if (custProfile) {
                if (userCapturedDetail !== null && (userCapturedDetail.fullName === null || userCapturedDetail.fullName === ''
                || userCapturedDetail.fullName === undefined)) {
                    dispatch({ type: 'USER_CAPTURE_VALIDATION_ERROR', data: 'Full Name must not be empty' });
                    return;
                }
                if (userCapturedDetail !== null && (userCapturedDetail.emailAddress === null || userCapturedDetail.emailAddress === ''
                || userCapturedDetail.emailAddress === undefined)) {
                    dispatch({ type: 'USER_CAPTURE_VALIDATION_ERROR', data: 'Email address must not be empty' });
                    return;
                }
                if (userCapturedDetail !== null && (userCapturedDetail.emailAddress !== null && userCapturedDetail.emailAddress !== ''
                && userCapturedDetail.emailAddress !== undefined &&
                    !userCapturedDetail.emailAddress.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/))) {
                    dispatch({ type: 'USER_CAPTURE_VALIDATION_ERROR', data: 'Email address is not valid' });
                    return;
                }
            }
            setParametersAndPageNameForWestPacProfiles(profileId, partnerId);
            dispatch(attemptFetchResult());
            dispatch({ type: 'SHOW_RESULT_SECTION', showResult: true });
            const answers = getAnswers(questions);
            const { currentDataPartition, postcode, loggedInUserDetail, billingToken, isEstimateDirect } = getState();
            const buildingList = toBuildingList(buildingGrp);
            getBuildingDetails(postcode, buildingList, productId);

            let { fullName, emailAddress } = getFullNameEmail(userCapturedDetail);
            return calculateApi({productId, profileId, propertyId, address, answers, currentDataPartition, estimateId : estimateID, riskId, buildingList}, partnerId,
                estimatePrice, estimateEdited, loggedInUserDetail.id, fullName, emailAddress, billingToken, isEstimateDirect)
                .then((response) => {
                    return dataRefreshErrrorForProductNineAndTen(response, productId, currentDataPartition);
                })
                .then((response) => handleJSONResponse(response))
                .then((body) => {
                    if (!body.status) {
                        calculateResponse({dispatch, body, estimateEdited, productId, profileId, estimateID}, getState, propertyId, defaultCalculation, calculateButtonClicked, partnerId, custProfile);
                    } else {
                        throw new Error(body.message);
                    }
                })
                .catch((error) => {
                    getError(error, dispatch, productId, profileId, propertyId, partnerId);
                });
        }
    };
};

export const downloadReport = (productId, profileId, propertyId, questions) => {
    const fileDownload = require('react-file-download');
    return async (dispatch, getStore) => {
        const store = getStore();
        const errors = await validate(questions.data);
        if (errors.length > 0) {
            dispatch({ type: 'VALIDATION_ERROR', errors: errors });
        } else {
            const answers = questions.data ? questions.data.map((question) => toDebugAnswer(question)) : [];
            const buildingList = toBuildingList(store.buildingGrp);
            return debugReportApi(productId, profileId, propertyId, answers, store.debugReport.token, buildingList)
                .then((response) => handleTextResponse(response))
                .then((body) => {
                    fileDownload(body, 'debug-report.csv');
                    dispatch(closeDebugDialog());
                })
                .catch((error) => dispatch(serverError(error)));
        }
    };
};

export const updateReturnData = (returnData) => {
    return { type: 'RETURN_TO_QUOTE', returnData: returnData };
};

export const siteExperience = () => {

    if (window.screen.width >= 320 && window.screen.width <= 480) {
        window.pageDetails.siteExperience = AnalyticsConstant.SITE_EXPERIENCE_MOBILE;
    }

    else if (window.screen.width >= 482 && window.screen.width <= 1024) {
        window.pageDetails.siteExperience = AnalyticsConstant.SITE_EXPERIENCE_TABLET;
    }

    else {
        window.pageDetails.siteExperience = AnalyticsConstant.SITE_EXPERIENCE_DESKTOP;
    }
    window.pageDetails.siteDomain = window.location.hostname;
    window.pageDetails.siteEnv = window.location.hostname.includes(DomainConstants.AU_PRD) ? "prod" : "test";
    window.pageDetails.ordinal = new Date().getTime();
    window.pageDetails.eventKey = `${window.pageDetails.siteBrand}:${window.pageDetails.siteExperience}_${window.pageDetails.pageType}_${window.pageDetails.pageName}`;
    window.pageDetails.pageURL = window.location.href;
};

export const returnToUrl = (profileId, rebuildCost, address, estimateId, returnUrl, hashingPrivateKey, goToQuoteOpenInNewTab, returnToQuoteCloseWindow, partnerId,
                                includeAddressInReturnUrl) => {
    let url = includeAddressInReturnUrl ? returnUrl + "?address=" + address + "&rebuildCost=" + rebuildCost : returnUrl + "?rebuildCost=" + rebuildCost;
    if (estimateId) {
        url = url + "&quoteId=" + estimateId;
    }

    if (UrlConstants.PROFILE_ID_WESTPAC.includes(profileId || partnerId)) {
        window.pageDetails.pageName = AnalyticsConstant.GO_TO_QUOTE;
        siteExperience();
        AnalyticsCustomScript('../WbcScriptSetPageDetail.js');
    }

    const valueForHash = address + ";" + estimateId + ";" + rebuildCost;
    hashApi(valueForHash, hashingPrivateKey)
        .then(response => {
            if (response.ok) {
                return response.text();
            } else {
                return response.text().then((error) => {
                    throw new ServerError(error, response.status)
                });
            }
        })
        .then(data => {
            if (estimateId === null) {
                windowActionWhenEstimateIdIsNull(goToQuoteOpenInNewTab, url, data);
            }
            else {
                window.opener.postMessage(JSON.stringify({
                    "address": address,
                    "rebuildCost": rebuildCost,
                    "quoteId": estimateId,
                    "checksum": data
                }), url);
                if (returnToQuoteCloseWindow) {
                    window.setTimeout(function () { window.close(); }, 50);
                }
            }
        })
};

export const saveInCompleteEstimate = ({productId, profileId, propertyId}, address, questions, riskId, buildingGrp, estimateID) => {
    return async (dispatch, getState) => {
            const answers = questions.data ? questions.data.map((question) => toAnswer(question)) : [];
            const buildingList = toBuildingList(buildingGrp);
            dispatch({ type: 'SAVE_INCOMPLETE_ESTIMATE'});
            const { loggedInUserDetail } = getState();
            return saveInCompleteEstimateApi({productId, profileId, propertyId}, address, answers, riskId, buildingList, estimateID, loggedInUserDetail.id)
                .then((response) => {
                    if(!response.ok){
                        throw new Error(response.message);
                    }
                    dispatch({ type: 'SAVED_INCOMPLETE_ESTIMATE'});
                })
    };
};

export const validateMandatoryFields = (productId, questions) => {
    return async (dispatch) => {
        const errors = await validate(productId, questions.data);
        dispatch({ type: 'VALIDATION_ERROR', errors: errors });
    }
};

export const newEstimateOnEdit = () => {
    return { type: 'NEW_ESTIMATE_ON_EDIT' };
};

function getAnswers(questions) {
    return questions.data ? questions.data.map((question) => toAnswer(question)) : [];
}

function getFullNameEmail(userCapturedDetail) {
    let emailAddress = userCapturedDetail !== null ? userCapturedDetail.emailAddress : null;
    let fullName = userCapturedDetail !== null ? userCapturedDetail.fullName : null;
    return { fullName, emailAddress };
}

function getBuildingDetails(postcode, buildingList, productId) {
    if (postcode != null) {
        buildingList.map((buildings, i) => {
            const index = findIndex(buildings, (building) => {
                return productId === UrlConstants.PRODUCT_ID_11 ? building.question === 302839 : building.question === 302860;
            });
            buildings[index] = { ...buildings[index], answer: postcode, visible: false };
            return buildings[index];
        });
    }
}

function setParametersAndPageNameForWestPacProfiles(profileId, partnerId) {
    if (UrlConstants.PROFILE_ID_WESTPAC.includes(profileId || partnerId)) {
        window.pageDetails.pageName = AnalyticsConstant.REBUILD_SUMMARY;
        siteExperience();
        AnalyticsCustomScript('../WbcScriptSetPageDetail.js');
        WestpacGTagScript();
        if (partnerId === undefined) {
            WestpacGenericGTagDLEnd();
        } else {
            WestpacGTagDLEnd();
        }
    }
}

function dataRefreshErrrorForProductNineAndTen(response, productId, currentDataPartition) {
    if (response.ok) {
        if ((productId !== UrlConstants.PRODUCT_ID_10) && (productId !== UrlConstants.PRODUCT_ID_9)
            && (productId !== UrlConstants.PRODUCT_ID_11)) {
            if (currentDataPartition !== "" && currentDataPartition !== response.headers.get('currentDataPartition')) {
                throw new DataRefreshError();
            }
        }
    }
    return response;
}

function getError(error, dispatch, productId, profileId, propertyId, partnerId) {
    let obj1 = {
        ProductId: productId,
        ProfileId: profileId,
        PropertyId: propertyId
    };
    if (error instanceof DataRefreshError) {
        dispatch({ type: 'UPDATE_REFRESH_WARNING', data: true });
        dispatch({ type: 'LOADING_COMPLETED' });
        let dataRefreshError = new AnalyticsError(obj1, "", "FAILURE",
            "Data has been refreshed", error, partnerId);
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.DATA_REFRESH_ERROR, dataRefreshError));
        dispatch({ type: 'UPDATE_ANALYTICS_TIME_TRACK', time: null });
    } else {
        let validationError = new AnalyticsError(obj1, "", "FAILURE",
            "Data validation failed", error, partnerId);
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.CALCULATION_DATA_VALIDATION_ERROR, validationError));
        dispatch(serverError(error));
    }
}

function calculateResponse({dispatch, body, estimateEdited, productId, profileId, estimateID}, getState, propertyId, defaultCalculation, calculateButtonClicked, partnerId, custProfile) {
    dispatch(updateResult(body));
    if (estimateEdited) {
        dispatch(newEstimateOnEdit());
        copyImageApi(productId, profileId, body.calculation.estimateId, estimateID)
            .then((response) => handleJSONResponse(response))
            .then((responseJson) => {
                const images = responseJson.images;
                return dispatch({ type: 'RECEIVE_CCE_IMAGES', images });
            });
    }
    if (body.message) {
        const { addressChosenTime } = getState();
        let timeTakenToCalculate = 0;
        if (addressChosenTime) {
            timeTakenToCalculate = (new Date().getTime() - addressChosenTime) / 1000;
        }
        let obj1 = {
            Estimate: body.calculation,
            TimeTakenToCalculate: timeTakenToCalculate,
            CalculateButtonClicked: !defaultCalculation && !calculateButtonClicked ? 1 : 0,
            PartnerId: partnerId
        };
        let newEstimate = productId === 8 ? new AnalyticsEstimate(productId, profileId, propertyId, "", "SUCCESS",
            "Calculation Over $2.5M", obj1) : new AnalyticsEstimate(productId, profileId, propertyId, "", "SUCCESS",
            "Calculation Over $2.5M", obj1);
        let segmentTrackingEventString = productId === 8 ? SegmentTrackingEventTypes.TOTAL_OVER_2_5M : SegmentTrackingEventTypes.TOTAL_OVER_2_5M
            dispatch(trackSegmentEvent(segmentTrackingEventString, newEstimate));
    } else {     
        let { addressChosenTime, updatedFinalMap } = getState();
        let timeTakenToCalculate = 0;
        if (addressChosenTime) {
            timeTakenToCalculate = (new Date().getTime() - addressChosenTime) / 1000;
        }
        let obj2 = {
            Estimate: body.calculation,
            TimeTakenToCalculate: timeTakenToCalculate,
            CalculateButtonClicked: !defaultCalculation && !calculateButtonClicked ? 1 : 0,
            PartnerId: partnerId
        };
        let newEstimate = new AnalyticsEstimate(productId, profileId, propertyId, "", "SUCCESS",
            "Calculation Updated", obj2);
        if (updatedFinalMap !== null) {
            updatedFinalMap.forEach((values,keys)=>{
                dispatch(trackSegmentEvent(SegmentTrackingEventTypes.FINAL_ANSWER_UPDATED + ' - ' + keys, values));
            });
            dispatch(clearSegmentEventMap());
        }     
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.UPDATED_CALCULATION, newEstimate));      
        dispatch({ type: 'UPDATE_ANALYTICS_TIME_TRACK', time: null });

        if(body != null && body.calculation != null && body.calculation.message != null) {
            newEstimate = productId === 8 ? new AnalyticsEstimate(productId, profileId, propertyId, "", "SUCCESS",
                "Calculation Over $2.5M", obj2) : new AnalyticsEstimate(productId, profileId, propertyId, "", "SUCCESS",
                "Calculation Over $2.5M", obj2);
            let segmentTrackingEventString = productId === 8 ? SegmentTrackingEventTypes.TOTAL_OVER_2_5M : SegmentTrackingEventTypes.TOTAL_OVER_2_5M
            dispatch(trackSegmentEvent(segmentTrackingEventString, newEstimate));
        }
    }
    // delaying emailReportIfCustomProfile action until ChartImage is rendered
    setTimeout(() => {
        dispatch(emailReportIfCustomProfile(custProfile));
    }, 800);
}

function windowActionWhenEstimateIdIsNull(goToQuoteOpenInNewTab, url, data) {
    if (goToQuoteOpenInNewTab) {
        window.open(url + "&checksum=" + data, "_blank");
    } else {
        window.open(url + "&checksum=" + data, "_self");
    }
}

export const fetchStrataBillingToken = (productId, profileId) => {
    return async (dispatch, getState) => {
        if (productId === '11') {
            return billingTokenApi(productId, profileId)
            .then((response) => {
                return response.text();
            })
            .then((billingAccessToken) => {
                dispatch({ type: 'SUCCEED_FETCH_BILLING_TOKEN', billingAccessToken });
            })
            .catch((error) => {
                dispatch({ type: 'FAIL_FETCH_BILLING_TOKEN', error });
            });
        }
    }
}
async function getResponseWhenQuestionTextEqualsPostcode(question, productId, errors) {
    if (question.text.toLowerCase() === "postcode") {
        const postcodeError = { questionId: question.id, errorMsg: "Please enter a valid postcode" };
        const response = await validateAnswerApi(productId, "postcode", question.answer);
        if (response && response.status !== 200) {
            errors.push(postcodeError);
        }
    }
}
