import React from 'react';
import {RadioButton, RadioButtonGroup} from 'material-ui';
import PropTypes from 'prop-types';
import UrlConstants from '../constants/UrlConstants';

const RadioImageBox = ({question, onChange, defaultAnswer, styles, productId, profileId}) => {
    const {answerTextColour, answerSelectedTextColour} = styles;
    const handleChange = (event, newSelection) => {
        onChange({'question': question.id, 'answer': newSelection});
    };

    const sortedOptions = question.options.sort((option1, option2) => option1.lineNo - option2.lineNo);

    const getErrorMessage = (defaultAnswer1) => {
        return isNaN(defaultAnswer1) ? <span style={{color:'red', fontSize:'12px'}}>Please provide an answer for this required field</span> : null;
    };

    return (
        <div>
            {getErrorMessage(defaultAnswer)}
            <RadioButtonGroup
                tabIndex={ 0 }
                name={question.text}
                className="radio-button-group"
                style={{paddingTop:'5px'}}
                id={question.id.toString()}
                onChange={handleChange} defaultSelected={defaultAnswer}>
                {
                    (sortedOptions).map((option) => {
                        return <RadioButton key={option.id}
                                            tabIndex={ 0 }
                                            value={option.id}
                                            id={option.id}
                                            label={<label hidden="true" htmlFor={option.id} >{option.label}</label>}
                                            uncheckedIcon={
                                                <div style={{
                                                    border: '1px solid',
                                                    color: answerTextColour,
                                                    borderRadius: '2px',
                                                }}>
                                                    <img src={option.image} alt={option.label}/>
                                                </div>}
                                            checkedIcon={
                                                <div style={{
                                                    border: '3px solid',
                                                    color: answerSelectedTextColour,
                                                    borderRadius: '2px',
                                                    backgroundColor: (productId === UrlConstants.PRODUCT_ID_10 || productId === UrlConstants.PRODUCT_ID_11) ? styles.sectionTileColour : styles.radioButtonSelectedColor
                                                }}>
                                                    <img src={option.imageSelected} alt={option.label} />
                                                </div>}
                                            disableTouchRipple={false}
                                            disableFocusRipple={false}
                        />;
                    })
                }
            </RadioButtonGroup>
        </div>
    );
};

RadioImageBox.propTypes = {
    question: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultAnswer: PropTypes.number,
    styles: PropTypes.object.isRequired,
};

export default RadioImageBox;