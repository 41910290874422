import React from 'react';
import {Card} from 'material-ui';
import Logo from '../assets/images/Sumsure_Footer.PNG';
import DisclaimerDialog from './DisclaimerDialog';
import FooterTermsOfUseDialog from './FooterTermsOfUseDialog';
import PropTypes from 'prop-types';
import {printDisclaimer} from '../util/PrintUtil';
import UrlConstants from '../constants/UrlConstants';

const onDisclaimerPrint = (productId) => {
    let sourceElement = null;
    if (productId === UrlConstants.PRODUCT_ID_8) {
        sourceElement ='nzDisclaimer';
    }
    else if (productId === UrlConstants.PRODUCT_ID_7) {
        sourceElement = 'auDisclaimer';
    }
    printDisclaimer(sourceElement);
    return true;
};

const Footer = ({termsAndConditions, disclaimer, state, styles, productId, profileId}) => {
    let copyrightTxt;
    let footerVersion;
    if(productId === UrlConstants.PRODUCT_ID_8){
        copyrightTxt = 'CoreLogic NZ Limited (Company Number 1129102). All rights reserved.'
        footerVersion = 'Cordell Sum Sure (Version 1.0)';

      } else {
      copyrightTxt = footerRights(productId);
      footerVersion = footerVersionName(productId);
      }
    const isCommercial = (productId === UrlConstants.PRODUCT_ID_9 ||  productId === UrlConstants.PRODUCT_ID_10 ||  productId === UrlConstants.PRODUCT_ID_11) ? true : false;
    const termsOfUse = isCommercial ? "Terms of Use" : "Terms of Use,";
    return (
            <div>
                <Card className="card">
                    <div className={isCommercial ? "footer commercial" : "footer"} style={{fontFamily: styles.globalFontFamily, backgroundColor: styles.footerBackgroundColour, color: styles.footerFontColour,
                        fontSize: '0.7 rem'}}>
                        { !isCommercial && <img src={Logo} alt="CoreLogic logo"/> }
                        <span style={{paddingTop: '12px', paddingLeft: '3px', fontFamily: 'Open Sans', fontSize: '0.7rem'}}> {footerVersion} </span>
                        <span style={{paddingTop: '12px', paddingLeft: '5px', fontFamily: 'Open Sans', fontSize: '0.7rem'}}> &copy;
                        <span> {new Date().getFullYear()}</span> {copyrightTxt}
                        &nbsp;<span tabIndex={ 0 }className="terms-of-use-link" style={{color: styles.footerFontColour, fontSize: '0.7rem', textDecoration: 'underline', cursor: 'pointer'}} onClick={(event) => {
                            event.preventDefault();
                            termsAndConditions.onOpen();
                        }} onKeyPress={(event) => {
                            event.preventDefault();
                            termsAndConditions.onOpen();
                        }}>{termsOfUse}</span>
                        &nbsp;
                       
                        
                       { !isCommercial && <span tabIndex={ 0 } className="disclaimer-link" style={{color: styles.footerFontColour, fontSize:'0.7rem', textDecoration: 'underline', cursor: 'pointer'}} 
                        onClick={(event) => {
                            event.preventDefault();
                            disclaimer.onOpen();
                        }} onKeyPress={(event) => {
                            event.preventDefault();
                            disclaimer.onOpen();
                        }}>Disclaimer</span>} and &nbsp;
                       
                       { !isCommercial && productId === UrlConstants.PRODUCT_ID_8 && <span>
                        <a id="privacyPolicy" href="https://www.corelogic.co.nz/about-us/privacy-policy" className="disclaimer-link" rel="noopener noreferrer" target="_blank"
                        style={{color: styles.footerFontColour, fontSize: '0.7rem', textDecoration: 'underline', cursor: 'pointer'}}>
                        Privacy Policy</a></span>}
                       { !isCommercial && productId === UrlConstants.PRODUCT_ID_7 && <span>
                        <a id="privacyPolicy" href="https://www.corelogic.com.au/legals/privacy-policy" className="disclaimer-link" rel="noopener noreferrer" target="_blank"
                        style={{color: styles.footerFontColour, fontSize: '0.7rem', textDecoration: 'underline', cursor: 'pointer'}}>
                        Privacy Policy</a></span>}

                        { isCommercial && productId === UrlConstants.PRODUCT_ID_10 && <span>
                        <a id="privacyPolicy" href="https://www.corelogic.co.nz/about-us/privacy-policy" className="disclaimer-link" rel="noopener noreferrer" target="_blank"
                        style={{color: styles.footerFontColour, fontSize: '0.7rem', textDecoration: 'underline', cursor: 'pointer'}}>
                        Privacy Policy</a></span>}

                        { isCommercial && productId === UrlConstants.PRODUCT_ID_11 && <span>
                        <a id="privacyPolicy" href="https://www.corelogic.com.au/legals/privacy-policy" className="disclaimer-link" rel="noopener noreferrer" target="_blank"
                        style={{color: styles.footerFontColour, fontSize: '0.7rem', textDecoration: 'underline', cursor: 'pointer'}}>
                        Privacy Policy</a></span>}
                       
                        </span>
                    </div>
                </Card>
                <FooterTermsOfUseDialog open={termsAndConditions.dialogOpen} onClose={termsAndConditions.onClose} styles={styles} productId={productId} profileId= {profileId}/>
                <DisclaimerDialog open={disclaimer.dialogOpen} onClose={disclaimer.onClose} state={state} name={disclaimer.disclaimerTextDialog} styles={styles} productId={productId}
                                  onPrint={onDisclaimerPrint} contentBefore={disclaimer.disclaimerContentBefore} contentAfter={disclaimer.disclaimerContentAfter}/>
            </div>
    );
};

Footer.propTypes = {
    termsAndConditions: PropTypes.shape({
        /*eslint-disable */
        dialogOpen: PropTypes.bool.isRequired,
        onOpen: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        /*eslint-enable */
    }).isRequired,
    disclaimer: PropTypes.shape({
        /*eslint-disable */
        dialogOpen: PropTypes.bool.isRequired,
        onOpen: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        disclaimerTextDialog: PropTypes.string,
        disclaimerContentBefore: PropTypes.string,
        disclaimerContentAfter: PropTypes.string,
        /*eslint-enable */
    }).isRequired,
    state: PropTypes.string,
    styles: PropTypes.object.isRequired,
};

export default Footer;

function footerRights(productId) {
    return (productId === UrlConstants.PRODUCT_ID_10) ? 'CoreLogic NZ Ltd (CoreLogic). All rights reserved.' : productId === UrlConstants.PRODUCT_ID_11 ? 'RP Data Pty Ltd t/a CoreLogic Asia Pacific (ABN 67 087 759 171). All rights reserved.' :
        'RP Data Pty Ltd t/a CoreLogic Asia Pacific (ABN 67 087 759 171). All rights reserved.';
}

function footerVersionName(productId){
    return productId === UrlConstants.PRODUCT_ID_7 ? 'Cordell Sum Sure (Version 1.1)' : productId === UrlConstants.PRODUCT_ID_11 ? 'Strata Sum Sure (Version 1.0)' : 'Cordell Commercial Estimator (Version 1.0)';
}
