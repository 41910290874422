import React from 'react';
import { findIndex } from 'lodash';
import HelpInformationDialogContainer from '../containers/HelpInformationDialogContainer';
import CCEDisclaimerSection from './CCEDisclaimerSection';
import ConfirmationMessage from './ConfirmationMessage';
import PropTypes from 'prop-types';
import LetUsKnowDialogContainer from "../containers/LetUsKnowDialogContainer";
import SectionHeader from "./SectionHeader";
import { Card } from "material-ui";
import CCEResultContainer from '../containers/CCEResultContainer';
import SpinnerContainer from '../containers/SpinnerContainer';
import ErrorMessage from './ErrorMessage';
import UrlConstants from '../constants/UrlConstants';
import { Button } from '@material-ui/core';
import history from "../util/history";
import apiBaseUrl from '../apiBaseUrl';

class CCERebuildCostPage extends React.Component {
    state = {
        paymentSuccessful: false,
        consentGiven: false,
        name: '',
        email: '',
        nameError: false,
        emailError: false,

    };

    componentWillMount() {
        const { onLoad, calculate, result, debug, isEmailDownload, updateCurrentTab, updateRiskDetail, currentTab, isEditEstimate, questions, postcode, productId } = this.props;
        onLoad();
        if (!debug && !isEmailDownload && (result.data === null || result.data.estimateId === null)) {
            const index = findIndex(questions.data, (question) => {
                return productId === UrlConstants.PRODUCT_ID_11 ? question.id === 302839 : question.id === 302860;
            });
            if (index >= 0) {
                questions.data[index] = { ...questions.data[index], answer: postcode, visible: false, isInvalid: false, answerSource: "grid", helpEnabled: true };
            }
            calculate();
        }
        if (!isEditEstimate && !isEmailDownload && currentTab === UrlConstants.RISK_DETAILS) {
            updateRiskDetail();
        }
        updateCurrentTab(UrlConstants.REBUILD_COST);
    }

    handleClose = () => {
        //nothing
    };

    scrollToFirstError = () => {
        const { errors } = this.props;
        if (errors && errors.length > 0) {
            let errorIds = errors.map(error => error.questionId);
            let elements = document.getElementsByClassName("questionLayout");
            let firstElement = [...elements].filter(element => errorIds.includes(parseInt(element.id.split("-")[1], 10)))[0];
            if (firstElement) {
                firstElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                })
            }
        }
    };


    tokenId = null;
    token = null;
    paymentId = null;
    // transactionId = null;

    handleConsentChange = (event) => {

        this.setState({ consentGiven: event.target.checked });
        this.forceUpdate();
    };
    handlePayment = async () => {
        const { name, email } = this.state;

        if (!name.trim()) {
            this.setState({ nameError: true });
            return;
        }
        if (!email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            this.setState({ emailError: true });
            return;
        }
        try {

            const response1 = await fetch(`https://rpgateway-access-uat.rpdata.com/access/oauth/token?grant_type=client_credentials&client_id=GFIqeckOLP7z6KFbSpccc4z3QooQm5nn&client_secret=BR5msAQPElnfHHst`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response1.ok) {
                throw new Error('Failed to fetch access token');
            }

            const tokenData = await response1.json();
            const accessToken = tokenData.access_token;
            this.token = accessToken;

            // const successRedirectUrl = window.location.href;

            const response2 = await fetch(`${apiBaseUrl()}api/payment/initiate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`,
                },
                body: JSON.stringify({
                    accountNumber: "645199",
                    accountGuid: "B7210C3F-84E3-4B03-9BC8-3B8835A61366",
                    businessUnit: "Australia",
                    amount: 200,
                    currency: "AUD",
                    txnType: "AUTH",
                    region: "AU",
                    source: "STRATA",
                    opportunityNumber: "OPP-123765",
                    salespersonEmail: "AUTH@example.org",
                    takePaymentOnAuth: false

                }),
            });

            if (!response2.ok) {
                throw new Error('Failed to initiate payment');
            }
            const paymentData = await response2.json();
            const paymentGatewayUrl = paymentData.redirectUrl;
            this.tokenId = paymentData.id;


            window.open(paymentGatewayUrl, '_blank');


        } catch (error) {
            console.error('Payment error:', error);
            alert('An error occurred during payment processing. Please try again.');
        }


    };

    handleCheckStatus = async () => {
        if (!this.tokenId) {
            alert('No payment ID found. Please initiate payment first.');
            return;
        }
        try {
            const response = await fetch(`${apiBaseUrl()}api/payment/status/${this.tokenId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                },
            });
            const data = await response.json();
            if (response.ok) {
                if (data && data.payment_id) {
                    this.paymentId = data.payment_id

                }
            } else {
                alert('Failed to tokenise and get paymentId');
            }
            const payload = {
                transactionId: "5e6f0725-13cd-4411-a2ef-314b95c59ec5",
                serviceId: 234741,
                customer: {
                    accountNumber: "645234199",
                    accountGuid: "B7210C3F-84E3-4B03-9BC8-3B8835A61366",
                    username: "bob.smith",
                    email: "bob.smith@corelogic.com.au",
                    firstName: "Bob",
                    lastName: "Smith",
                    address1: "Level 5",
                    address2: "825 Ann Street",
                    address3: "Fortitude Valley",
                    address4: "4006",
                    businessUnit: "Australia"
                },
                products: [
                    {
                        productDescription: "Short Form Valuation 1",
                        instanceDescription1: "Short Form Valuation",
                        instanceDescription2: "1/81 Patterson Street Ringwood East VIC 3135",
                        instanceDescription3: null,
                        productCode: 32118,
                        unitPrice: 10.00,
                        gst: true,
                        quantity: 1
                    }
                ]
            };
            const response3 = await fetch(`${apiBaseUrl()}api/payment/authcomplete/${this.paymentId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`,
                },
                body: JSON.stringify(payload),
            });
            const paymentDataFinal = await response3.json();
            if (response3.ok) {
                if (
                    paymentDataFinal &&
                    paymentDataFinal.status &&
                    paymentDataFinal.status === 'SUCCESS'
                ) {

                    this.setState({ paymentSuccessful: true });
                }

            } else {
                alert('Failed to check final payment status');
            }
        } catch (error) {
            alert('An error occurred while checking the payment status.');
        }
    };

    render() {
        const { styles, text, handleChange, isEditEstimate, isEmailDownload, companyDisclaimer, divisionDisclaimer, isEstimateDirect } = this.props;
        const handlePrevious = (event, newValue) => {
            handleChange(UrlConstants.IMAGE_MANAGEMENT);
        }
        const handleNext = () => {
            history.push(this.props.homePath);
        }
        let cceResult;
        let disclaimerSection;
        let disclaimerSectionTop;
        if (this.props.showDisclaimerAtBottom === false) {
            disclaimerSection = "";
            disclaimerSectionTop =
                <CCEDisclaimerSection state={this.props.addressChosen.state}
                    contentBefore={text.disclaimerCalculator1}
                    contentAfter={text.disclaimerCalculator2} styles={styles}
                    productId={this.props.productId}
                    companyDisclaimer={companyDisclaimer}
                    divisionDisclaimer={divisionDisclaimer}
                    showDisclaimerAtBottom={this.props.showDisclaimerAtBottom} />;
        }
        if (this.props.debug) {
            cceResult = <CCEResultContainer
                productId={this.props.productId}
                profileId={this.props.profileId}
                propertyId={this.props.addressChosen.propertyId}
                addressChosen={this.props.addressChosen.suggestion}
                onLetUsKnowDialogOpen={this.props.openLetUsKnowDialog}
            />;

        } else if (this.props.result.data && this.props.result.data.variables.length > 0) {
            if (this.props.attributesCheck && !this.props.showResult) {
                cceResult =
                    <Card className="card">
                        <SectionHeader name={this.props.rebuildCostSectionHeader}
                            color={this.props.styles.sectionTileColour} styles={this.props.styles} />
                        <ConfirmationMessage styles={this.props.styles}
                            showResultSection={() => this.props.showResultAfterConfirmation(true)} /></Card>;

            } else {
                if (this.props.showDisclaimerAtBottom === true) {
                    disclaimerSectionTop = "";
                    disclaimerSection =
                        <CCEDisclaimerSection state={this.props.addressChosen.state}
                            contentBefore={text.disclaimerCalculator1}
                            contentAfter={text.disclaimerCalculator2} styles={styles}
                            productId={this.props.productId}
                            companyDisclaimer={companyDisclaimer}
                            divisionDisclaimer={divisionDisclaimer}
                            showDisclaimerAtBottom={this.props.showDisclaimerAtBottom} />;
                }
                cceResult = <CCEResultContainer
                    productId={this.props.productId}
                    profileId={this.props.profileId}
                    propertyId={this.props.addressChosen.propertyId}
                    addressChosen={this.props.addressChosen.suggestion}
                    onLetUsKnowDialogOpen={this.props.openLetUsKnowDialog}
                />;
            }
        } else {
            if (this.props.result.error) {
                cceResult = <Card className="card">
                    <SectionHeader name={this.props.rebuildCostSectionHeader}
                        color={this.props.styles.sectionTileColour} styles={this.props.styles} />
                    <div className="error">
                        <ErrorMessage productId={this.props.productId} profileId={this.props.profileId} propertyId={this.props.addressChosen.propertyId}
                            address={this.props.address} onLetUsKnowDialogOpen={this.props.openLetUsKnowDialog} styles={this.props.styles} message={this.props.result.error} />
                    </div>
                </Card>;
            }

        }

        return (
            <div className="questions-wrapper rebuild-cost">
                <SpinnerContainer />
                {disclaimerSectionTop}
                {!this.state.paymentSuccessful && isEstimateDirect && (
                    <div>
                        <p>Please provide your details</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                            <div style={{ flex: '1', marginRight: '10px' }}>
                                <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>
                                    Name <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    value={this.state.name}
                                    onChange={(e) => this.setState({ name: e.target.value, nameError: false })}
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        border: this.state.nameError ? '1px solid red' : '1px solid #ccc',
                                        borderRadius: '4px',
                                    }}
                                />
                                {this.state.nameError && <span style={{ color: 'red', fontSize: '12px' }}>Name is required.</span>}
                            </div>
                            <div style={{ flex: '1', marginLeft: '10px' }}>
                                <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>
                                    Email <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    type="email"
                                    value={this.state.email}
                                    onChange={(e) => this.setState({ email: e.target.value, emailError: false })}
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        border: this.state.emailError ? '1px solid red' : '1px solid #ccc',
                                        borderRadius: '4px',
                                    }}
                                />
                                {this.state.emailError && <span style={{ color: 'red', fontSize: '12px' }}>Valid email is required.</span>}
                            </div>
                        </div>

                    </div>
                )}


                {!this.state.paymentSuccessful && isEstimateDirect && (
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                onChange={this.handleConsentChange}
                            />
                            &nbsp; By submitting my personal information, I confirm:
                            <div className="consent" style={{ fontSize: '16px' }}>
                                <label>
                                    {/* <p>By submitting my personal information, I confirm:</p> */}
                                    <ol style={{ listStyleType: 'lower-alpha' }}>
                                        <li>I have read and agree to the <strong>Strata Sum Sure Terms of Use</strong> and <a href="https://www.corelogic.com.au/legals/privacy-policy" target="_blank" rel="noopener noreferrer"> CoreLogic’s Privacy Policy </a> and <a href="https://www.corelogic.com.au/legals/terms-conditions" target="_blank" rel="noopener noreferrer"> General Terms and Conditions</a>,
                                            I agree to any personal information being used as described in CoreLogic’s Privacy Policy; and</li><br />
                                        <li>I confirm that I have reviewed the provided information and understand that it cannot be edited or changed once payment is processed.</li><br />
                                        <li> I consent to my personal information being disclosed to and used by {""}
                                            <span class="tooltip"><u>CoreLogic’s</u><span class="tooltiptext"><strong>CoreLogic</strong> means :
                                                <ol style={{ listStyleType: 'lower-alpha' }}>
                                                    <li>RP Data Pty Ltd trading as CoreLogic Asia Pacific (ABN 67 087 759 171) and its Australian subsidiaries and related bodies corporate, including Valuation Exchange Pty Ltd, Cordell Information Pty Ltd, OnTheHouse.com.au Pty Ltd , Residex Pty Ltd (CoreLogic Australia); and/or</li><br />
                                                    <li>CoreLogic NZ Limited and its New Zealand subsidiaries and related bodies corporate (CoreLogic NZ).</li>
                                                </ol></span></span> {""}
                                            <span class="tooltip"><strong>Commercial Partners</strong><span class="tooltiptext"><strong>Commercial Partners</strong> means insurance companies, insurance brokers, and other providers in or to the insurance industry who have a contractual relationship with CoreLogic from to time.</span></span>
                                            {""} for the purposes of (and I consent to receiving from them) updates about products & services, marketing and special offers, and I understand that if I do not agree to my personal information being so used, I will not be able to use the {""}
                                            <span class="tooltip"><strong>Strata Sum Sure Services</strong><span class="tooltiptext"><strong>Strata Sum Sure Services</strong> means the services available on this website including access to and provision of the Sum Sure Estimate for the nominated property.</span></span>.
                                            You can opt-out at any time. See our <a href="https://www.corelogic.com.au/legals/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> to find out more.</li>
                                    </ol>
                                </label>
                            </div>
                        </label>
                    </div>)}
                <br></br>
                {!this.state.paymentSuccessful && isEstimateDirect && (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '15px', marginTop: '20px' }}>
                        <Button
                            className="pay_now_button"
                            variant="contained"
                            color="primary"
                            onClick={this.handlePayment}
                            disabled={!this.state.consentGiven || !this.state.name || !this.state.email}
                            style={{
                                width: '120px',
                                padding: '8px',
                                textTransform: 'none',
                                fontSize: '14px',
                            }}
                        >
                            Pay Now
                        </Button>
                        <Button
                            className="check_status_button"
                            variant="outlined"
                            color="secondary"
                            onClick={this.handleCheckStatus}
                            disabled={!this.state.consentGiven}
                            style={{
                                width: '120px',
                                padding: '8px',
                                textTransform: 'none',
                                fontSize: '14px',
                                borderColor: '#3f51b5',
                                color: '#3f51b5',
                            }}
                        >
                            Check Status
                        </Button>
                    </div>
                )}

                <br></br>
                {isEstimateDirect ? (
                    <div>

                        {this.state.paymentSuccessful ? (
                            <>
                                {cceResult}
                                {disclaimerSection}
                            </>
                        ) : (
                            <div>Please complete your payment to view results.</div>
                        )}
                    </div>
                ) : <>
                    {cceResult}
                    {disclaimerSection}
                </>}
                <div className="action-nav">
                    <div className="nav-previous">
                        <Button className="action_previous" variant="outlined" id="tab-2"
                            rounded="true"
                            size="large"
                            tabIndex={0}
                            disabled={this.isDisabledStatus(isEditEstimate, isEmailDownload)}
                            disableTouchRipple={true}
                            disableFocusRipple={false}
                            onClick={handlePrevious}
                        > &larr; Image Management
                        </Button>

                    </div>
                    {!isEstimateDirect && (
                        <div className="nav-next">
                        <Button className="action_next" variant="outlined" id="tab-5"
                            rounded="true"
                            size="large"
                            tabIndex={0}
                            disabled={this.isDisabledStatus(isEditEstimate, isEmailDownload)}
                            disableTouchRipple={true}
                            disableFocusRipple={false}
                            onClick={handleNext}
                        > Go Back To Dashboard Menu &rarr;
                        </Button>
                    </div>
                    )}
                    
                </div>
                <HelpInformationDialogContainer />
                <LetUsKnowDialogContainer />
            </div>
        );
    }

    isDisabledStatus(isEditEstimate, isEmailDownload) {
        return isEditEstimate || isEmailDownload ? true : false;
    }
}

CCERebuildCostPage.propTypes = {
    displayRefreshWarning: PropTypes.bool.isRequired,
    addressChosen: PropTypes.object.isRequired,
    questions: PropTypes.object.isRequired,
    result: PropTypes.object.isRequired,
    debug: PropTypes.bool,
    styles: PropTypes.object,
    text: PropTypes.object,
    attributesCheck: PropTypes.bool.isRequired,
    showResultAfterConfirmation: PropTypes.func.isRequired,
    showDisclaimerAtBottom: PropTypes.bool.isRequired,
    stateProductId: PropTypes.string,
    partner: PropTypes.string,
    onLoad: PropTypes.func.isRequired,
    calculate: PropTypes.func.isRequired,
};

export default CCERebuildCostPage;