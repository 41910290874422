import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

const StrataAddressSearch = ({ newValue, onChange, name, fontFamilyName }) => {
    const handleInputChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <Form.Field style={{ fontFamily: fontFamilyName }}>
            <input
                type="text"
                className="address"
                placeholder="Enter address"
                value={newValue}
                onChange={handleInputChange}
                id={name}
                style={{ width: '100%', padding: '0.5em', fontSize: '1em' }}
            />
        </Form.Field>
    );
};

StrataAddressSearch.propTypes = {
    newValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    fontFamilyName: PropTypes.string,
};

export default StrataAddressSearch;
