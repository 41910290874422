import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import {render} from 'react-dom';
import {applyMiddleware, createStore} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import {getMuiTheme, MuiThemeProvider} from 'material-ui/styles';
import 'babel-polyfill';
import './scss/index.scss';
import {themeStyle} from './style/styles';
import reducer from './reducers/reducer';
import AppContainer from './containers/AppContainer';
import {createLogger} from 'redux-logger';
import {createTracker} from 'redux-segment';
import { PersistGate } from 'redux-persist/integration/react';
import indexStore from './indexStore';
import UrlConstants from './constants/UrlConstants';



const middleware = [thunk];
const restoreUrl = window.location.href;
if (!restoreUrl.includes('/products/' + UrlConstants.PRODUCT_ID_7)
    && !restoreUrl.includes('/products/' + UrlConstants.PRODUCT_ID_8)) 
    {
    const { store, persistor } = indexStore();
    const muiTheme = getMuiTheme(themeStyle);  
    const documentTitle = restoreUrl.includes('strata') ? "Strata" : "CCE";

    class Root extends React.Component {
        render() {
            window.document.title = documentTitle;
            return (
                <MuiThemeProvider muiTheme={muiTheme}>
                    <Provider store={store}>
                        <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
                            <AppContainer />
                        </PersistGate>
                    </Provider>
                </MuiThemeProvider>
            );
        }
    }

    render(<Root />, document.getElementById('root'));
} else {
    const tracker = createTracker();
    if (process.env.NODE_ENV === 'development') {
        const logger = createLogger();
        middleware.push(logger);
    }

    const store = createStore(reducer, applyMiddleware(...middleware, tracker));
    const muiTheme = getMuiTheme(themeStyle);



    class Root extends React.Component {
        render() {
            window.document.title = "Sum Sure";
            return (
                <MuiThemeProvider muiTheme={muiTheme}>
                    <Provider store={store}>
                        <AppContainer/>
                    </Provider>
                </MuiThemeProvider>
            );
        }
    }

    render(<Root/>, document.getElementById('root'));
}
