import React from 'react';
import Header from "./Header";
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import FooterContainer from '../containers/FooterContainer';
import history from '../util/history';
import { parse } from 'qs';
import PropTypes from 'prop-types';
import configLocal from '../commercialConfig';
import UrlConstants from '../constants/UrlConstants';
import NotFoundPage from './NotFoundPage';
import SpinnerContainer from '../containers/SpinnerContainer';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import TermsOfUse from "../constants/TermsOfUse";
import { withRouter } from 'react-router-dom'; 

class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            invalidUrl: false,
            openDialog: false,
            continueWithoutLoginFlag: false,
        };
    }

    getRedirectURL = (productId) => {
        const { STRATA_LOGIN_URL, CLAUD_LOGIN_URL, DEV_BASE_URL } = configLocal;
        const replaceLocalhostWithDevURL = (url) => {
            const LOCALHOST = 'http://localhost:3000';
            return url.includes(LOCALHOST) ? url.replace(LOCALHOST, DEV_BASE_URL) : url;
        };
        console.log("Product ID is:", productId);
        if (productId === UrlConstants.PRODUCT_ID_11) {
            return replaceLocalhostWithDevURL(STRATA_LOGIN_URL);
        } else {
            return replaceLocalhostWithDevURL(CLAUD_LOGIN_URL);
        }
    };

    handleAgree = () => {
        if (this.state.continueWithoutLoginFlag) {
            this.continueWithoutLogin();
        } else {
            const { restoreURL, productId, profileId } = this.props;
            restoreURL(productId, profileId);
            window.localStorage.setItem('productId', productId);
            window.localStorage.setItem('profileId', profileId);
            const redirectURL = this.getRedirectURL(productId);
            window.location.replace(redirectURL);
        }
        
    };

    handleCancel = () => {
        this.setState({ openDialog: false });
    };

    loginAction = () => {
        const { productId } = this.props;
        if (productId === UrlConstants.PRODUCT_ID_11) {
            this.setState({ openDialog: true });
        } else {
            this.handleAgree();
        }
    };

    getLoginUrl = () => {
        let restoreUrl = window.localStorage.getItem('restoreUrl') || this.props.homePath;
        if ((restoreUrl === null || restoreUrl === "") && this.props.productId !== null
            && this.props.profileId !== null && typeof (this.props.productId) !== 'undefined'
            && typeof (this.props.profileId) !== 'undefined') {
            restoreUrl = `/products/${this.props.productId}/profiles/${this.props.profileId}`;
        }
        window.history.replaceState(null, null, window.location.pathname);
        return restoreUrl;
    }

    onLoginComplete = () => {
        let url = this.getLoginUrl() + `/manage`;
        history.push(url);
        window.localStorage.removeItem('restoreUrl');
    }

    componentDidUpdate(prevProps) {
        const { loggedIn, tokenStatus, onResetTokenDetails,
            restoreURL, homePath, claudtoken, loggedInUserDetail } = this.props;

        if (loggedIn && !prevProps.loggedIn) {
            this.onLoginCompleteWhenIdPresent(loggedInUserDetail);
        }
        if (tokenStatus !== null && prevProps.tokenStatus !== tokenStatus &&
            (tokenStatus === "TOKEN EXPIRED" || tokenStatus === "TOKEN INVALID")) {
            window.localStorage.setItem('productId', this.props.productId);
            window.localStorage.setItem('profileId', this.props.profileId);
            if (tokenStatus === "TOKEN EXPIRED") {
                onResetTokenDetails();
                restoreURL();
                window.location.replace(`${configLocal.CLAUD_LOGIN_URL}`);
            } else {
                window.history.replaceState(null, null, window.location.pathname);
                history.push(homePath);
                this.claudLogout();
            }
        }
        else if (claudtoken && !claudtoken.error
            && claudtoken.error !== prevProps.claudtoken.error
            && !loggedIn && claudtoken != null && claudtoken.message !== null
            && claudtoken.message !== undefined && claudtoken.message.includes("Login Failed")) {
            let restoreUrl = window.localStorage.getItem('restoreUrl') || this.props.homePath;
            if (restoreUrl === null && this.props.productId !== null
                && this.props.profileId !== null && typeof (this.props.productId) !== 'undefined'
                && typeof (this.props.profileId) !== 'undefined') {
                restoreUrl = `/products/${this.props.productId}/profiles/${this.props.profileId}`;
            }
            window.history.replaceState(null, null, window.location.pathname);
            history.push(restoreUrl);
            this.claudLogout();
        }
    }

    onLoginCompleteWhenIdPresent(loggedInUserDetail) {
        if (loggedInUserDetail !== null && loggedInUserDetail.id !== null) {
            this.onLoginComplete();
        }
    }

    claudLogout() {
        var win = window.open(`${configLocal.CLAUD_LOGOUT_URL}`);
        setTimeout(function () {
            win.close();
        }, 900);
    }

    componentWillMount() {
        const { onSaveCode, loggedIn, onFetchClaudToken,
            productId, profileId, tokenStatus, loadLoginPage, loggedInUserDetail } = this.props;
        if (window.location.href.toUpperCase().includes('?CODE')) {
            const code = this.getCode();
            if (code) {
                onSaveCode(code);
                let product = productId || window.localStorage.getItem('productId');
                let profile = profileId || window.localStorage.getItem('profileId');
                onFetchClaudToken(product, profile, code);
                window.localStorage.removeItem('productId');
                window.localStorage.removeItem('profileId');
            }
        } else if ((window.location.href.toUpperCase().includes('/PRODUCTS/'
            + UrlConstants.PRODUCT_ID_10 + '/PROFILES/')
            || window.location.href.toUpperCase().includes('/PRODUCTS/'
                + UrlConstants.PRODUCT_ID_9 + '/PROFILES/')
            || window.location.href.toUpperCase().includes('/PRODUCTS/'
                + UrlConstants.PRODUCT_ID_11 + '/PROFILES/'))
            && !window.location.href.toUpperCase().includes('/UNDEFINED')) {
            this.loginForProductAndProfile(productId, profileId, loadLoginPage, loggedIn, tokenStatus, loggedInUserDetail);
        } else if (productId === UrlConstants.PRODUCT_ID_7 || productId === UrlConstants.PRODUCT_ID_8
            || profileId === UrlConstants.PROFILE_ID_411) {
            let url = `/products/${productId}/profiles/${profileId}`;
            history.push(url);
        } else {
            this.setState({ invalidUrl: true });
        }
    }

    loginForProductAndProfile(productId, profileId, loadLoginPage, loggedIn, tokenStatus, loggedInUserDetail) {
        if (typeof (productId) !== 'undefined' && typeof (profileId) !== 'undefined') {
            loadLoginPage(productId, profileId);
        }
        if (loggedIn && ((tokenStatus === null || tokenStatus === "") ||
            (tokenStatus != null && tokenStatus === "TOKEN NOT EXPIRED"))
            && loggedInUserDetail !== null && loggedInUserDetail.id !== null) {
            this.onLoginComplete();
        }
    }


    continueWithoutLogin = () => {
        const { productId, profileId } = this.props;
        this.props.onIsEstimateDirectFlag();
        this.props.history.push(`/products/${productId}/profiles/${profileId}/estimate`); 
    };
    getCode() {
        const query = parse(window.location.search.substr(1));
        return query && query.code;
    }

    render() {
        const { openDialog, invalidUrl } = this.state;
        const { productId, claudtoken } = this.props;
        const styles = {
            headerLogoLeftPaddingTop: "15px",
        };
        const button = <CustomFlatButtonContainer
            className="input"
            id="login-button"
            label="Login"
            type="primary"
            keyboardFocus={false}
            tabIndex={0}
            onPress={this.loginAction}
            style={{
                fontFamily: styles.globalFontFamily,
                borderRadius: styles.buttonRadius,
                width: "6rem",
                backgroundColor: "rgb(244, 67, 54)",
                color: "white",
            }}
        />;

        // const continueWithoutLoginLink = (          
        //     <div> <div
        //          style={{ marginTop: '1rem', textAlign: 'center', cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
        //          onClick={() => this.setState({continueWithoutLoginFlag: true, openDialog: true })}
        //      >
        //          Proceed without Login? Click here
        //      </div>
        //      Chargeable ($199 + GST)
        //      </div>
        //  );
        const error = (claudtoken !== null && claudtoken.message !== null &&
            !claudtoken.message.includes("Logged out successfully.")) ?
            <span className="user-validation" ><h3><center>{claudtoken.message}
                {claudtoken.message.includes("Failed to connect to the server") &&
                    <div className="auth-label"> Failed to log in: The authentication servers are currently down for maintenance.</div>}
            </center></h3> </span> : null
        return (
            <div>
                {!invalidUrl ? (
                    this.props.productId === UrlConstants.PRODUCT_ID_10 ? (
                        <div className="homepage cce">
                            <SpinnerContainer />
                            <Header debug={false} styles={styles}
                                images={{ headerLogoLeftUrl: "https://content.rpdata.com/zap/dev/CL-Commercial_Calculator_Logo_New.png" }}
                                text={{ headerTitle: "" }} />
                            <div className='login'>
                                <div id='title' className='title'>
                                    {error}
                                    <h1>Welcome to Cordell Commercial Estimator</h1>
                                    {button}
                                </div>
                            </div>
                            <FooterContainer />
                        </div>
                    ) : productId === UrlConstants.PRODUCT_ID_11 ? (
                        <div
                            className="homepage cce"
                            style={{
                                backgroundImage: `url('https://content.rpdata.com/zap/dev/GettyImages-1317298616.jpg')`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                            }}
                        >
                            <SpinnerContainer />
                            <Header debug={false} styles={styles}
                                images={{ headerLogoLeftUrl: "https://content.rpdata.com/zap/dev/CL-Strata_Sum_Sure_Logo_New.png" }}
                                text={{ headerTitle: "" }} />
                            <div className='login'>
                                <div id="title" className="title">
                                    {error}
                                    <div
                                        style={{
                                            background: 'radial-gradient(circle at 50% 50%, #FFFFFF 27%, #FFE8A3 68%, #A9C4D1 82%)',
                                            padding: '30px',
                                            borderRadius: '20px',
                                            display: 'inline-block',
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                            margin: '20px 0',
                                            textAlign: 'center',
                                            transition: 'transform 0.3s ease',
                                            position: 'relative',
                                            overflow: 'hidden'
                                        }}
                                        onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                                        onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}>
                                        <h1 style={{ margin: '0 0 10px', color: '#333', fontWeight: null }}>Welcome to Strata Sum Sure</h1>

                                        {button}
                                        {/* {continueWithoutLoginLink} */}
                                        <Dialog open={openDialog} onClose={this.handleCancel} maxWidth="md" fullWidth={true}>
                                            <DialogTitle>Terms of Use</DialogTitle>
                                            <DialogContent>

                                                {TermsOfUse.STRATA}

                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={this.handleAgree} color="primary">
                                                    AGREE
                                                </Button>
                                                <Button onClick={this.handleCancel} color="primary">
                                                    CANCEL
                                                </Button>

                                            </DialogActions>
                                        </Dialog>
                                    </div>
                                </div>
                            </div>
                            <FooterContainer />
                        </div>
                    ) : (
                        <NotFoundPage />
                    )
                ) : (
                    <NotFoundPage />
                )}
            </div>
        );
    }
}

LoginPage.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    onFetchClaudToken: PropTypes.func.isRequired,
    onSaveCode: PropTypes.func.isRequired,
    isLogout: PropTypes.bool.isRequired,
    onResetTokenDetails: PropTypes.func.isRequired,
};



export default withRouter(LoginPage);
